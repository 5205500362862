import { type Filter } from '@perry/query-builder';
import LogicalField from './LogicalField';
import type { Field } from './types';
export * from './types';

type Props = {
  value?: Filter<any>;
  fields: Field[];
  onChange?: (value: Filter<any>) => void;
  disabled?: boolean;
};

const defaultFilter: Filter<any> = {
  type: 'logical',
  operator: 'and',
  filters: [],
};

export const QueryBuilder = ({
  value: valueProp = { ...defaultFilter },
  fields,
  onChange,
  disabled = false,
}: Props) => {
  const value: Filter<any> =
    valueProp.type === 'logical'
      ? valueProp
      : {
          type: 'logical',
          operator: 'and',
          filters: [valueProp],
        };

  return (
    <LogicalField
      className={disabled ? 'opacity-50 pointer-events-none' : ''}
      fields={fields}
      value={value}
      onChange={onChange}
      onRemoveClick={() => onChange?.({ ...defaultFilter })}
    />
  );
};
