import PageTitle from '@components/PageTitle';
import { Button, Input, Select, SelectItem, Textarea } from '@nextui-org/react';
import { RewardConfigTriggerType, RewardVisibility } from '@perry/db';
import type { BaseCreateRewardTemplateParams } from '@perry/shared-types';
import { createRewardTemplate, getRewardTemplate, updateRewardTemplate } from '@services/templates';
import _ from 'lodash';
import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { MultiplierConfigDataCard } from './components/MultiplierConfigDataCard';
import { RewardConfigDataCard } from './components/RewardConfigDataCard';
import { UniquenessConfigDataCard } from './components/UniquenessConfigDataCard';

const RewardTemplateDetails = () => {
  const { id } = useParams<{ id: string }>();
  const isNewTemplate = !id;
  const [template, setTemplate] = React.useState<BaseCreateRewardTemplateParams | null>(null);

  const [formData, setFormData] = React.useState<BaseCreateRewardTemplateParams>({
    name: ' ',
    title: ' ',
    description: '',
    configData: [],
    triggerType: 'VISIT',
    pointAmount: 0,
    visibility: 'PRIVATE',
    uniquenessConfig: { fields: ['id'] },
    multiplierConfig: undefined,
  });

  const navigate = useNavigate();

  const handleUpdate = (update: Partial<BaseCreateRewardTemplateParams>) => {
    setFormData((params) => ({ ...params, ...update }));
  };

  const handleSubmit = async () => {
    try {
      if (isNewTemplate) {
        const response = await createRewardTemplate(formData);
        if (!response.template) {
          toast.error('Failed to create reward template.');
          return;
        }
        toast.success('Reward Template created successfully');
      } else {
        const response = await updateRewardTemplate(parseInt(id), formData);
        if (!response.template) {
          toast.error('Failed to create reward template.');
          return;
        }
        toast.success('Reward template updated successfully');
      }
      navigate('/templates');
    } catch (error) {
      console.error(error);
      toast.error(isNewTemplate ? 'Error creating mission' : 'Error updating mission');
    }
  };

  const handleCancel = () => {
    navigate('/templates');
  };

  const getSelectedTemplate = React.useCallback(async () => {
    if (!id) return;

    const response = await getRewardTemplate(id);
    if (response) setTemplate(response.template);
  }, [id]);

  React.useEffect(() => {
    if (!isNewTemplate) {
      getSelectedTemplate();
    }
  }, [getSelectedTemplate, isNewTemplate]);

  React.useEffect(() => {
    if (template) {
      setFormData({
        name: template.name,
        description: template.description,
        configData: template.configData,
        pointAmount: template.pointAmount,
        title: template.title,
        triggerType: template.triggerType,
        uniquenessConfig: template.uniquenessConfig,
        visibility: template.visibility,
        multiplierConfig: template.multiplierConfig,
      });
    }
  }, [template]);

  const saveIsDisabled = React.useMemo(() => _.isEqual(formData, template), [formData, template]);

  return (
    <>
      <div className="flex flex-col h-full w-full">
        <PageTitle>
          {isNewTemplate ? 'Create Reward For Agency' : 'Reward Template For Agency'}
        </PageTitle>
        <div className="grid grid-cols-12 gap-4 mt-6 grow">
          <div className="col-span-6 flex flex-col gap-4">
            <Input
              label="Title"
              type="text"
              labelPlacement="outside"
              name="title"
              value={formData.title}
              onChange={(e) => handleUpdate({ title: e.target.value, name: e.target.value })}
            />
            <Textarea
              label="Description"
              type="text"
              labelPlacement="outside"
              name="description"
              value={formData.description}
              minRows={2}
              onChange={(e) => handleUpdate({ description: e.target.value })}
            />
            <Select
              label="What entity triggers this reward?"
              selectedKeys={[formData.triggerType]}
              labelPlacement="outside"
              name="triggerType"
              value={formData.triggerType}
              onChange={(e) =>
                handleUpdate({ triggerType: e.target.value as RewardConfigTriggerType })
              }
              required
            >
              <SelectItem
                value={RewardConfigTriggerType.PROFILE}
                key={RewardConfigTriggerType.PROFILE}
              >
                Profile
              </SelectItem>
              <SelectItem value={RewardConfigTriggerType.VISIT} key={RewardConfigTriggerType.VISIT}>
                Visit
              </SelectItem>
            </Select>
            <Input
              label="Point Value"
              type="number"
              labelPlacement="outside"
              name="pointAmount"
              value={formData.pointAmount?.toString()}
              onChange={(e) => handleUpdate({ pointAmount: parseInt(e.target.value) })}
            />
            <Select
              label="Post Visibility"
              labelPlacement="outside"
              name="visibility"
              selectedKeys={[formData.visibility]}
              value={formData.visibility}
              onChange={(e) => handleUpdate({ visibility: e.target.value as RewardVisibility })}
              required
            >
              <SelectItem value={RewardVisibility.PRIVATE} key={RewardVisibility.PRIVATE}>
                Private
              </SelectItem>
              <SelectItem value={RewardVisibility.PUBLIC} key={RewardVisibility.PUBLIC}>
                Public
              </SelectItem>
              <SelectItem
                value={RewardVisibility.LOCATION_SPECIFIC}
                key={RewardVisibility.LOCATION_SPECIFIC}
              >
                Specific to Location
              </SelectItem>
            </Select>
          </div>
          <div className="col-span-6 flex flex-col gap-4">
            <RewardConfigDataCard
              configData={formData.configData}
              setConfigData={(data) => handleUpdate({ configData: data })}
            />
            <UniquenessConfigDataCard
              uniquenessConfig={formData.uniquenessConfig}
              setUniquenessConfig={(data) => handleUpdate({ uniquenessConfig: data })}
            />
            <MultiplierConfigDataCard
              multiplierConfig={formData.multiplierConfig ?? null}
              setMultiplierConfig={(data) => handleUpdate({ multiplierConfig: data })}
            />
          </div>
        </div>
        <div className="flex justify-end gap-6 mt-6">
          <Button type="button" variant="bordered" className="w-56" onClick={handleCancel}>
            Cancel
          </Button>
          <Button
            onClick={handleSubmit}
            disabled={saveIsDisabled}
            variant="solid"
            className="w-56 bg-theme-primary text-white"
          >
            {isNewTemplate ? 'Create' : 'Save'}
          </Button>
        </div>
      </div>
    </>
  );
};

export default RewardTemplateDetails;
