import { useAsyncList } from '@react-stately/data';
import { listIntegrationFields, listProcessingStatus } from '@services/processingStatus';
import type { IntegrationField, ProcessingStatus } from '@services/processingStatus/types';
import { useEffect, useState } from 'react';

export const useProcessingStatusPage = () => {
  const [open, setOpen] = useState(false);
  const [provider, setProvider] = useState<string>('');
  const processingStatusList = useAsyncList<ProcessingStatus>({
    load: async () => {
      const res = await listProcessingStatus();

      return {
        items: res.data || [],
      };
    },
  });

  const integrationFieldsList = useAsyncList<IntegrationField>({
    load: async () => {
      if (!provider) {
        return {
          items: [],
        };
      }

      const res = await listIntegrationFields(provider);

      return {
        items: res.data || [],
      };
    },
  });

  useEffect(() => {
    integrationFieldsList.reload();
  }, [integrationFieldsList]);

  const handleSeeFields = (selectedProvider: string) => {
    setProvider(selectedProvider);
    setOpen(true);
  };

  return {
    processingStatus: processingStatusList.items,
    integrationFields: integrationFieldsList.items,
    isProcessingStatusLoading: processingStatusList.isLoading,
    isIntegrationFieldsLoading: integrationFieldsList.isLoading,
    handleSeeFields,
    open,
    setOpen,
  };
};
