export const templatesColumns = [
  {
    key: 'title',
    label: 'NAME',
  },
  {
    key: 'description',
    label: 'DESCRIPTION',
  },
  {
    key: 'type',
    label: 'TYPE',
  },
  {
    key: 'createdAt',
    label: 'CREATED_AT',
  },
  {
    key: 'updatedAt',
    label: 'UPDATED_AT',
  },
  {
    key: 'actions',
    label: 'ACTIONS',
  },
];
