import { Button, Card, CardBody, CardHeader, Input, Select, SelectItem } from '@nextui-org/react';
import type { BaseMissionEvaluationConfig } from '@perry/shared-types';
import React from 'react';

type MissionEvaluationConfigDataCardProps = {
  missionEvaluationConfig: BaseMissionEvaluationConfig[];
  setMissionEvaluationConfig: (config?: BaseMissionEvaluationConfig[]) => void;
};

export const MissionEvaluationConfigDataCard = ({
  missionEvaluationConfig,
  setMissionEvaluationConfig,
}: MissionEvaluationConfigDataCardProps) => {
  const updateSingleMissionEvaluationConfig = React.useCallback(
    (idx: number, config: BaseMissionEvaluationConfig) => {
      setMissionEvaluationConfig(missionEvaluationConfig.map((c, i) => (i === idx ? config : c)));
    },
    [missionEvaluationConfig, setMissionEvaluationConfig],
  );
  return (
    <Card className="p-2 pt-0">
      <CardHeader>
        <div className="flex flex-row justify-between w-full items-center">
          <span className="text-lg font-bold">Mission Evaluation</span>
        </div>
      </CardHeader>
      <CardBody>
        <div className="flex flex-col gap-2">
          {missionEvaluationConfig.map((config, idx) => (
            <div key={idx} className="flex flex-row gap-2">
              <Select
                label="Evaluation Type"
                value={config.type ?? ''}
                selectedKeys={[config.type]}
                onChange={(e) => {
                  const value = e.target.value;
                  if (value === 'percentage') {
                    return updateSingleMissionEvaluationConfig(idx, {
                      type: 'percentage',
                      percentage: 1,
                    });
                  } else if (value === 'count') {
                    return updateSingleMissionEvaluationConfig(idx, {
                      type: 'count',
                      requiredCount: 0,
                    });
                  } else if (value === 'total_shift_hours') {
                    return updateSingleMissionEvaluationConfig(idx, {
                      type: 'total_shift_hours',
                      hours: 0,
                    });
                  }
                }}
                className="w-full"
              >
                <SelectItem key="percentage" value="percentage">
                  Percentage
                </SelectItem>
                <SelectItem key="count" value="count">
                  Count
                </SelectItem>
                <SelectItem key="total_shift_hours" value="total_shift_hours">
                  Total Shift Hours
                </SelectItem>
              </Select>
              {config.type === 'percentage' && (
                <Input
                  type="number"
                  label="Percentage value"
                  value={config.percentage.toString()}
                  onChange={(e) =>
                    updateSingleMissionEvaluationConfig(idx, {
                      type: 'percentage',
                      percentage: parseFloat(e.target.value),
                    })
                  }
                />
              )}
              {config.type === 'count' && (
                <Input
                  type="number"
                  label="Count value"
                  value={config.requiredCount.toString()}
                  onChange={(e) =>
                    updateSingleMissionEvaluationConfig(idx, {
                      type: 'count',
                      requiredCount: parseInt(e.target.value),
                    })
                  }
                />
              )}
              {config.type === 'total_shift_hours' && (
                <Input
                  type="number"
                  label="Total Shift Hours value"
                  value={config.hours.toString()}
                  onChange={(e) =>
                    updateSingleMissionEvaluationConfig(idx, {
                      type: 'total_shift_hours',
                      hours: parseFloat(e.target.value),
                    })
                  }
                />
              )}
            </div>
          ))}
          <div className="flex flex-row gap-2">
            <Button
              color="secondary"
              onClick={() =>
                setMissionEvaluationConfig([
                  ...missionEvaluationConfig,
                  { type: 'count', requiredCount: 1 },
                ])
              }
            >
              Add Criteria
            </Button>
          </div>
        </div>
      </CardBody>
    </Card>
  );
};
