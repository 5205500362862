import PageTitle from '@components/PageTitle';
import { Delete } from '@mui/icons-material';
import { IconButton, Typography } from '@mui/material';
import { Button, Input, Spinner, Switch, Textarea } from '@nextui-org/react';
import { MissionEvaluationConfigDataCard } from '@pages/Templates/Details/components/MissionEvaluationConfigDataCard';
import { MissionRecurrenceConfigDataCard } from '@pages/Templates/Details/components/MissionRecurrenceConfigDataCard';
import { RewardConfigDataCard } from '@pages/Templates/Details/components/RewardConfigDataCard';
import { DefaultMissionTemplate } from '@pages/Templates/Details/utils';
import type { BaseMissionConfigWithRewardConfig, BaseRewardConfigData } from '@perry/shared-types';
import { deleteRewardConfig, getMissionConfig, updateMissionConfig } from '@services/templates';
import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

const MissionConfigDetails = () => {
  const { missionId, id: agencyId } = useParams<{ missionId: string; id: string }>();
  const isNewTemplate = !missionId;
  const [template, setTemplate] = React.useState<BaseMissionConfigWithRewardConfig | null>(null);

  const navigate = useNavigate();
  React.useEffect(() => console.log({ template }), [template]);
  const handleUpdate = (update: Partial<BaseMissionConfigWithRewardConfig>) => {
    setTemplate((params) => {
      if (!params) return params;
      return { ...params, ...update };
    });
  };

  const handleSubmit = async () => {
    try {
      if (!template || !missionId) return null;
      const response = await updateMissionConfig(parseInt(missionId), template);
      if (!response.missionId) {
        toast.error('Failed to update reward template.');
        return;
      }
      toast.success('Reward updated successfully');
    } catch (error) {
      console.error(error);
      toast.error(isNewTemplate ? 'Error creating reward' : 'Error updating reward');
    }
  };

  const handleUpdateRewardConfig = (update: BaseRewardConfigData[]) => {
    setTemplate((params) => {
      if (!params) return params;
      return {
        ...params,
        rewardConfigs: params.rewardConfigs.map((t) => ({ ...t, configData: update })),
      };
    });
  };

  const handleDelete = async () => {
    window.confirm('Are you sure you want to delete this reward? This is irreversible.');
    if (!missionId) return;
    const response = await deleteRewardConfig(parseInt(missionId), true);
    if (!response.rewardId) {
      toast.error('Failed to delete reward template.');
      return;
    }
    navigate(`/agencies/${agencyId}/missions`);
    toast.success('Reward deleted successfully');
  };

  const handleCancel = () => {
    navigate(`/agencies/${agencyId}/rewards`);
  };

  const getSelectedTemplate = React.useCallback(async () => {
    if (!missionId) return;

    const response = await getMissionConfig(missionId);
    if (response) setTemplate(response.config);
  }, [missionId]);

  React.useEffect(() => {
    if (!isNewTemplate) {
      getSelectedTemplate();
    }
  }, [getSelectedTemplate, isNewTemplate]);

  if (!template)
    return (
      <div className="flex justify-center items-center h-full w-full">
        <Spinner />
      </div>
    );

  return (
    <>
      <div className="flex flex-col h-full w-full">
        <PageTitle>
          <div className="flex justify-between flex-row item-center">
            {isNewTemplate ? 'Create Reward Template' : 'Reward Template Edit Details'}
            <div className="flex flex-row items-center gap-2">
              <Typography variant="body1">Active?</Typography>
              <Switch
                checked={template.activated}
                onChange={(e) => handleUpdate({ activated: e.target.checked })}
              />
              <IconButton onClick={handleDelete}>
                <Delete />
              </IconButton>
            </div>
          </div>
        </PageTitle>
        <div className="grid grid-cols-12 gap-4 mt-6 grow">
          <div className="col-span-6 flex flex-col gap-4">
            <Input
              label="Title"
              type="text"
              labelPlacement="outside"
              name="title"
              value={template.title}
              onChange={(e) => handleUpdate({ title: e.target.value })}
            />
            <Textarea
              label="Description"
              type="text"
              labelPlacement="outside"
              name="description"
              value={template.description}
              minRows={2}
              onChange={(e) => handleUpdate({ description: e.target.value })}
            />
            <Input
              label="Point Value"
              type="number"
              labelPlacement="outside"
              name="pointAmount"
              value={template.pointAmount?.toString()}
              onChange={(e) => handleUpdate({ pointAmount: parseInt(e.target.value) })}
            />
            <MissionEvaluationConfigDataCard
              missionEvaluationConfig={template.missionEvaluationConfig}
              setMissionEvaluationConfig={(data) => handleUpdate({ missionEvaluationConfig: data })}
            />
          </div>
          <div className="col-span-6 flex flex-col gap-4">
            <MissionRecurrenceConfigDataCard
              missionRecurrenceConfig={template.recurrenceConfig}
              setMissionRecurrenceConfig={(data) => handleUpdate({ recurrenceConfig: data })}
            />
            {/* TODO: Add config for the reward config trigger type when supported*/}
            <RewardConfigDataCard
              configData={
                template.rewardConfigs[0]?.configData ??
                DefaultMissionTemplate.rewardTemplates[0].configData
              }
              setConfigData={(data) => handleUpdateRewardConfig(data)}
            />
          </div>
        </div>
        <div className="flex justify-end gap-6 mt-6">
          <Button type="button" variant="bordered" className="w-56" onClick={handleCancel}>
            Cancel
          </Button>
          <Button
            onClick={handleSubmit}
            variant="solid"
            className="w-56 bg-theme-primary text-white"
          >
            {isNewTemplate ? 'Create' : 'Save'}
          </Button>
        </div>
      </div>
    </>
  );
};

export default MissionConfigDetails;
