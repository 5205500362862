import GoBackButton from '@components/GoBackButton';
import { PageTitle } from '@components/PageTitle/styles';
import { Info } from '@mui/icons-material';
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableColumn,
  TableHeader,
  TableRow,
  Tooltip,
} from '@nextui-org/react';
import { getAgencyDetails } from '@services/agencies';
import type { AgencyDetailsObject } from '@services/agencies/types';
import { getMissionsForAgency } from '@services/templates';
import type { AgencyMissionRow } from '@services/templates/types';
import { DateTime } from 'luxon';
import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { CreateMissionModal } from './components/CreateMissionModal';

type MissionColumnKey = 'title' | 'description' | 'last_updated' | 'activated' | 'actions';

export const missionColumns = [
  {
    key: 'title',
    label: 'Title',
  },
  {
    key: 'description',
    label: 'Description',
  },
  {
    key: 'condition',
    label: 'Condition',
  },
  {
    key: 'last_updated',
    label: 'Last Updated At',
  },
  { key: 'activated', label: 'Activated?' },
  { key: 'actions', label: 'Actions' },
];

export const AgencyMissionsList = () => {
  const [agency, setAgency] = useState<AgencyDetailsObject>();
  const [missionsList, setMissionsList] = useState<AgencyMissionRow[]>([]);

  React.useEffect(() => {
    if (!agency?.publicId) return;

    getMissionsForAgency(agency.publicId).then((fullList) => {
      const sortedList = fullList.missions.sort(
        (a, b) => new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime(),
      );

      if (sortedList) setMissionsList(sortedList);
    });
  }, [agency, setMissionsList]);

  const navigate = useNavigate();
  const params = useParams();
  const { id } = params;
  const [createOpen, setCreateOpen] = useState(false);

  React.useEffect(() => {
    if (!id) return;

    getAgencyDetails(id).then((agencyResponse) => {
      if (agencyResponse) setAgency(agencyResponse);
    });
  }, [id, setAgency]);

  const renderCell = React.useCallback(
    (agencyMissionRow: AgencyMissionRow, columnKey: MissionColumnKey): React.ReactNode => {
      if (columnKey === 'title') return agencyMissionRow.title;
      else if (columnKey === 'description') return agencyMissionRow.description;
      else if (columnKey === 'last_updated')
        return DateTime.fromJSDate(new Date(agencyMissionRow.updatedAt)).toFormat('yyyy-MM-dd');
      else if (columnKey === 'actions') {
        return (
          <div className="flex gap-1">
            <Tooltip content="Details">
              <Info
                color="secondary"
                style={{ cursor: 'pointer' }}
                onClick={() => navigate(`/agencies/${id}/missions/${agencyMissionRow.id}`)}
              />
            </Tooltip>
          </div>
        );
      } else return agencyMissionRow.activated ? 'Activated' : 'Not Activated';
    },
    [id, navigate],
  );

  return (
    <div className="flex flex-col w-full gap-5">
      <GoBackButton label="Agency's details" navigatePath={`/agencies/${id}`} />
      <div className="flex flex-row justify-between">
        <PageTitle>Mission for {agency?.name}</PageTitle>
        <Button
          onClick={() => setCreateOpen(true)}
          style={{
            background: '#6558F5',
            color: 'white',
          }}
        >
          New Mission
        </Button>
      </div>

      <Table
        aria-label="Mission table"
        isStriped
        shadow="md"
        classNames={{
          base: 'max-h-[70vh] overflow-auto p-3',
          table: 'max-h-[50vh]',
        }}
      >
        <TableHeader columns={missionColumns}>
          {(column) => (
            <TableColumn className="text-white bg-purple-500" key={column.key}>
              {column.label}
            </TableColumn>
          )}
        </TableHeader>
        <TableBody items={missionsList} emptyContent={'No rows to display.'}>
          {(item: AgencyMissionRow) => (
            <TableRow>
              {(columnKey) => (
                <TableCell>{renderCell(item, columnKey as MissionColumnKey)}</TableCell>
              )}
            </TableRow>
          )}
        </TableBody>
      </Table>
      <CreateMissionModal
        isOpen={createOpen}
        setIsOpen={setCreateOpen}
        agencyId={agency?.id ?? 0}
        agencyPublicId={agency?.publicId ?? ''}
      />
    </div>
  );
};
