import { apiRoutes } from '@services/constants';
import { getToken } from '@utils/auth';
import * as fetch from '@utils/fetch';
import type { ListIntegrationFieldsResponse, ListProcessingStatusResponse } from './types';

export const listProcessingStatus = (): Promise<ListProcessingStatusResponse> =>
  fetch.get<ListProcessingStatusResponse>(
    apiRoutes.processingStatus,
    {},
    {
      headers: { Authorization: `Bearer ${getToken()}` },
    },
  );

export const listIntegrationFields = (provider: string): Promise<ListIntegrationFieldsResponse> =>
  fetch.get<ListIntegrationFieldsResponse>(
    `${apiRoutes.processingStatus}/${provider}/fields`,
    {},
    {
      headers: { Authorization: `Bearer ${getToken()}` },
    },
  );
