import PageTitle from '@components/PageTitle';
import { Info, Search } from '@mui/icons-material';
import {
  Button,
  getKeyValue,
  Input,
  Spinner,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableColumn,
  TableHeader,
  TableRow,
  Tabs,
  Tooltip,
} from '@nextui-org/react';
import { useInfiniteScroll } from '@nextui-org/use-infinite-scroll';
import { useAsyncList } from '@react-stately/data';
import { getTemplates } from '@services/templates';
import type { TemplateConfig, TemplateType } from '@services/templates/types';
import type { ChangeEvent, Key } from 'react';
import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { templatesColumns } from './utils';

const Templates = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [type, setType] = useState<TemplateType>('reward');
  const [filter, setFilter] = useState('');
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);

  const navigate = useNavigate();

  const list = useAsyncList<TemplateConfig>({
    load: async () => {
      setIsLoading(true);
      const res = await getTemplates(type, page, filter);
      setIsLoading(false);
      setHasMore(res.items.length === 20); // Assuming 20 is the page size
      return {
        items: res.items,
      };
    },
  });

  const [loaderRef, scrollerRef] = useInfiniteScroll({
    hasMore,
    onLoadMore: () => {
      setPage((prev) => prev + 1);
      list.loadMore();
    },
  });

  const onChangeFilter = (e: ChangeEvent<HTMLInputElement>) => {
    setFilter(e.target.value);
  };

  const onChangeStatus = (key: Key) => {
    setType(key === 'reward' ? 'reward' : 'mission');
  };

  useEffect(() => {
    list.reload();
    setPage(1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [type, filter]);

  const renderCell = useCallback(
    (templateConfig: TemplateConfig, columnKey: Key) => {
      switch (columnKey) {
        case 'actions':
          return (
            <div>
              <Tooltip content="Details">
                <Info
                  color="secondary"
                  style={{ cursor: 'pointer' }}
                  onClick={() => navigate(`/templates/${templateConfig.type}/${templateConfig.id}`)}
                />
              </Tooltip>
            </div>
          );

        default:
          return getKeyValue(templateConfig, columnKey.toString());
      }
    },
    [navigate],
  );

  return (
    <>
      <PageTitle>Templates</PageTitle>
      <div className="flex content-center gap-5 my-5">
        <Input
          aria-label="Search"
          size="sm"
          startContent={<Search />}
          onChange={onChangeFilter}
          placeholder="Search..."
          className="w-72"
        />
        <Button
          color="secondary"
          onClick={() => navigate('reward/new')}
          className="bg-theme-primary text-white"
        >
          New Reward Template
        </Button>
        <Button
          color="secondary"
          onClick={() => navigate('mission/new')}
          className="bg-theme-primary text-white"
        >
          New Mission Template
        </Button>
      </div>
      <Tabs
        color="secondary"
        className="pl-3"
        aria-label="Tabs colors"
        size="sm"
        onSelectionChange={onChangeStatus}
      >
        <Tab key="reward" title={'Reward'} />
        <Tab key="mission" title={'Mission'} />
      </Tabs>
      <Table
        baseRef={scrollerRef}
        aria-label="Templates table"
        isStriped
        shadow="md"
        bottomContent={
          hasMore ? (
            <div className="flex justify-center w-full">
              <Spinner ref={loaderRef} color="secondary" />
            </div>
          ) : null
        }
        classNames={{
          base: 'max-h-[70vh] overflow-auto p-3',
          table: 'max-h-[50vh]',
        }}
      >
        <TableHeader columns={templatesColumns}>
          {(column) => (
            <TableColumn className="text-white bg-purple-500" key={column.key}>
              {column.label}
            </TableColumn>
          )}
        </TableHeader>
        <TableBody
          isLoading={isLoading}
          items={list.items}
          loadingContent={<Spinner color="secondary" />}
          emptyContent={'No rows to display.'}
        >
          {(item: TemplateConfig) => (
            <TableRow key={item.id}>
              {(columnKey) => <TableCell>{renderCell(item, columnKey)}</TableCell>}
            </TableRow>
          )}
        </TableBody>
      </Table>
    </>
  );
};

export default Templates;
