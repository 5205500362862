import { Input, Select, SelectItem } from '@nextui-org/react';
import {
  type BaseRewardShiftCondition,
  type BaseRewardShiftDateConfig,
  type DateComparisonType,
  HolidayEnum,
  type NumberComparisonType,
  type RewardShiftBooleanField,
  RewardShiftBooleanFields,
  type RewardShiftDateField,
  RewardShiftDateFields,
  type RewardShiftNumberField,
  RewardShiftNumberFields,
  type RewardShiftStringField,
  RewardShiftStringFields,
  type StringComparisonType,
} from '@perry/shared-types';

const DateComparisonForm = ({
  condition,
  onChange,
}: {
  condition: Partial<BaseRewardShiftDateConfig> & { type: 'date' };
  onChange: (condition: BaseRewardShiftCondition) => void;
}) => (
  <div className="flex flex-col gap-4">
    <Select
      label="Field"
      value={condition.field}
      selectedKeys={condition.field ? [condition.field] : undefined}
      onChange={(e) =>
        onChange({
          ...condition,
          field: e.target.value as RewardShiftDateField,
        } as BaseRewardShiftCondition)
      }
    >
      {RewardShiftDateFields.map((field) => (
        <SelectItem key={field} value={field}>
          {field}
        </SelectItem>
      ))}
    </Select>
    {condition.field !== undefined && (
      <Select
        label="Comparison Type"
        value={condition.comparisonType}
        selectedKeys={condition.comparisonType ? [condition.comparisonType] : undefined}
        onChange={(e) => {
          if (!condition.field) return; // required for typescript typing
          const comparisonType = e.target.value as DateComparisonType;
          let newCondition: BaseRewardShiftCondition;

          switch (comparisonType) {
            case 'in_threshold':
              newCondition = {
                ...condition,
                field: condition.field,
                comparisonType,
                thresholdMinutes: 0,
                comparedToDate: 'startDate',
              };
              break;
            case 'lte':
            case 'gte':
              newCondition = {
                ...condition,
                field: condition.field,
                comparisonType,
                comparedToDate: 'startDate',
                minutesDelay: 0,
              };
              break;
            case 'null':
            case 'not_null':
              newCondition = {
                ...condition,
                field: condition.field,
                comparisonType,
              };
              break;
            case 'is_holiday':
              newCondition = {
                ...condition,
                field: condition.field,
                comparisonType,
                holidays: [HolidayEnum.HOLIDAY],
              };
              break;
            default:
              throw new Error(`Unhandled comparison type: ${comparisonType}`);
          }
          onChange(newCondition as BaseRewardShiftCondition);
        }}
      >
        <SelectItem key="in_threshold" value="in_threshold">
          Within threshold
        </SelectItem>
        <SelectItem key="lte" value="lte">
          Less than or equal
        </SelectItem>
        <SelectItem key="gte" value="gte">
          Greater than or equal
        </SelectItem>
        <SelectItem key="null" value="null">
          Is null
        </SelectItem>
        <SelectItem key="not_null" value="not_null">
          Is not null
        </SelectItem>
        <SelectItem key="is_holiday" value="is_holiday">
          Is holiday
        </SelectItem>
      </Select>
    )}
    {condition.comparisonType === 'in_threshold' && (
      <>
        <Input
          type="number"
          label="Threshold (minutes)"
          value={condition.thresholdMinutes?.toString() ?? '0'}
          onChange={(e) =>
            onChange({
              ...condition,
              thresholdMinutes: parseInt(e.target.value),
            } as BaseRewardShiftCondition)
          }
        />
        <Select
          label="Compared To Date"
          value={condition.comparedToDate}
          selectedKeys={condition.comparedToDate ? [condition.comparedToDate] : undefined}
          onChange={(e) =>
            onChange({
              ...condition,
              comparedToDate: e.target.value as RewardShiftDateField,
            } as BaseRewardShiftCondition)
          }
        >
          {RewardShiftDateFields.map((field) => (
            <SelectItem key={field} value={field}>
              {field}
            </SelectItem>
          ))}
        </Select>
      </>
    )}
    {(condition.comparisonType === 'lte' || condition.comparisonType === 'gte') && (
      <>
        <Select
          label="Compared To Date"
          value={condition.comparedToDate}
          selectedKeys={condition.comparedToDate ? [condition.comparedToDate] : undefined}
          onChange={(e) =>
            onChange({
              ...condition,
              comparedToDate: e.target.value as RewardShiftDateField,
            } as BaseRewardShiftCondition)
          }
        >
          {RewardShiftDateFields.map((field) => (
            <SelectItem key={field} value={field}>
              {field}
            </SelectItem>
          ))}
        </Select>
        <Input
          type="number"
          label="Minutes Delay"
          value={condition.minutesDelay?.toString() ?? '0'}
          onChange={(e) =>
            onChange({
              ...condition,
              minutesDelay: parseInt(e.target.value),
            } as BaseRewardShiftCondition)
          }
        />
      </>
    )}
    {condition.comparisonType === 'is_holiday' && (
      <Select
        label="Holidays"
        selectionMode="multiple"
        selectedKeys={condition.holidays ? condition.holidays : undefined}
        value={condition.holidays}
        onChange={(e) => {
          const selectedValues = Array.isArray(e.target.value) ? e.target.value : [e.target.value];
          onChange({
            ...condition,
            holidays: selectedValues as HolidayEnum[],
          } as BaseRewardShiftCondition);
        }}
      >
        {Object.values(HolidayEnum).map((holiday) => (
          <SelectItem key={holiday} value={holiday}>
            {holiday.replace(/_/g, ' ')}
          </SelectItem>
        ))}
      </Select>
    )}
  </div>
);
const NumberComparisonForm = ({
  condition,
  onChange,
}: {
  condition: Partial<BaseRewardShiftCondition> & { type: 'number' };
  onChange: (condition: BaseRewardShiftCondition) => void;
}) => (
  <div className="flex flex-col gap-4">
    <Select
      label="Field"
      selectedKeys={condition.field ? [condition.field] : undefined}
      value={condition.field}
      onChange={(e) =>
        onChange({
          ...condition,
          field: e.target.value as RewardShiftNumberField,
        } as BaseRewardShiftCondition)
      }
    >
      {RewardShiftNumberFields.map((field) => (
        <SelectItem key={field} value={field}>
          {field}
        </SelectItem>
      ))}
    </Select>

    {condition.field && (
      <Select
        selectedKeys={condition.comparisonType ? [condition.comparisonType] : undefined}
        label="Comparison Type"
        value={condition.comparisonType}
        onChange={(e) => {
          if (!condition.field) return;
          const comparisonType = e.target.value as NumberComparisonType;
          let newCondition: BaseRewardShiftCondition;

          switch (comparisonType) {
            case 'lte':
            case 'gte':
            case 'eq':
              newCondition = {
                ...condition,
                field: condition.field,
                comparisonType,
                comparedValue: 0,
              };
              break;
            case 'null':
            case 'not_null':
              newCondition = {
                field: condition.field,
                ...condition,
                comparisonType,
              };
              break;
            default:
              throw new Error(`Unhandled comparison type: ${comparisonType}`);
          }
          onChange(newCondition as BaseRewardShiftCondition);
        }}
      >
        <SelectItem key="eq" value="eq">
          Equals
        </SelectItem>
        <SelectItem key="lte" value="lte">
          Less than or equal
        </SelectItem>
        <SelectItem key="gte" value="gte">
          Greater than or equal
        </SelectItem>
        <SelectItem key="null" value="null">
          Is null
        </SelectItem>
        <SelectItem key="not_null" value="not_null">
          Is not null
        </SelectItem>
      </Select>
    )}
    {condition.comparisonType &&
      (condition.comparisonType === 'lte' ||
        condition.comparisonType === 'gte' ||
        condition.comparisonType === 'eq') && (
        <Input
          type="number"
          label="Compared Value"
          value={condition.comparedValue?.toString() ?? '0'}
          onChange={(e) =>
            onChange({
              ...condition,
              comparedValue: parseFloat(e.target.value),
            } as BaseRewardShiftCondition)
          }
        />
      )}
  </div>
);
const StringComparisonForm = ({
  condition,
  onChange,
}: {
  condition: Partial<BaseRewardShiftCondition> & { type: 'string' };
  onChange: (condition: BaseRewardShiftCondition) => void;
}) => (
  <div className="flex flex-col gap-4">
    <Select
      label="Field"
      value={condition.field}
      selectedKeys={condition.field ? [condition.field] : undefined}
      onChange={(e) =>
        onChange({
          ...condition,
          field: e.target.value as RewardShiftStringField,
        } as BaseRewardShiftCondition)
      }
    >
      {RewardShiftStringFields.map((field) => (
        <SelectItem key={field} value={field}>
          {field}
        </SelectItem>
      ))}
    </Select>

    {condition.field && (
      <Select
        label="Comparison Type"
        selectedKeys={condition.comparisonType ? [condition.comparisonType] : undefined}
        value={condition.comparisonType}
        onChange={(e) => {
          if (!condition.field) return;
          const comparisonType = e.target.value as StringComparisonType;
          let newCondition: BaseRewardShiftCondition;

          switch (comparisonType) {
            case 'eq':
            case 'neq':
            case 'contains':
            case 'not_contains':
              newCondition = {
                ...condition,
                field: condition.field,
                comparisonType,
                comparedToValue: '',
              };
              break;
            case 'is_one_of':
            case 'is_not_one_of':
            case 'contains_one_of':
            case 'not_contains_one_of':
              newCondition = {
                ...condition,
                field: condition.field,
                comparisonType,
                comparedToValue: [],
              };
              break;
            case 'null':
            case 'not_null':
              newCondition = {
                field: condition.field,
                ...condition,
                comparisonType,
              };
              break;
            default:
              throw new Error(`Unhandled comparison type: ${comparisonType}`);
          }
          onChange(newCondition as BaseRewardShiftCondition);
        }}
      >
        <SelectItem key="eq" value="eq">
          Equals
        </SelectItem>
        <SelectItem key="contains" value="contains">
          Contains
        </SelectItem>
        <SelectItem key="is_one_of" value="is_one_of">
          Is one of
        </SelectItem>
        <SelectItem key="is_not_one_of" value="is_not_one_of">
          Is not one of
        </SelectItem>
        <SelectItem key="contains_one_of" value="contains_one_of">
          Contains one of
        </SelectItem>
        <SelectItem key="not_contains_one_of" value="not_contains_one_of">
          Does not contain one of
        </SelectItem>
        <SelectItem key="null" value="null">
          Is null
        </SelectItem>
        <SelectItem key="not_null" value="not_null">
          Is not null
        </SelectItem>
      </Select>
    )}

    {(condition.comparisonType === 'eq' || condition.comparisonType === 'contains') && (
      <Input
        label="Compared Value"
        value={condition.comparedToValue?.toString() ?? ''}
        onChange={(e) =>
          onChange({
            ...condition,
            comparedToValue: e.target.value,
          } as BaseRewardShiftCondition)
        }
      />
    )}

    {(condition.comparisonType && condition.comparisonType === 'is_one_of') ||
      condition.comparisonType === 'is_not_one_of' ||
      condition.comparisonType === 'contains_one_of' ||
      (condition.comparisonType === 'not_contains_one_of' && (
        <Input
          label="Compared Values (comma-separated)"
          value={
            Array.isArray(condition.comparedToValue) ? condition.comparedToValue.join(',') : ''
          }
          onChange={(e) =>
            onChange({
              ...condition,
              comparedToValue: e.target.value.split(',').map((v) => v.trim()),
            } as BaseRewardShiftCondition)
          }
        />
      ))}
  </div>
);
const BooleanComparisonForm = ({
  condition,
  onChange,
}: {
  condition: Partial<BaseRewardShiftCondition> & { type: 'boolean' };
  onChange: (condition: BaseRewardShiftCondition) => void;
}) => (
  <div className="flex flex-col gap-4">
    <Select
      label="Field"
      selectedKeys={condition.field ? [condition.field] : undefined}
      value={condition.field}
      onChange={(e) =>
        onChange({
          ...condition,
          field: e.target.value as RewardShiftBooleanField,
        } as BaseRewardShiftCondition)
      }
    >
      {RewardShiftBooleanFields.map((field) => (
        <SelectItem key={field} value={field}>
          {field}
        </SelectItem>
      ))}
    </Select>

    {condition.field && (
      <Select
        label="Comparison Type"
        selectedKeys={condition.comparisonType ? [condition.comparisonType] : undefined}
        value={condition.comparisonType}
        onChange={(e) =>
          onChange({
            ...condition,
            comparisonType: e.target.value,
          } as BaseRewardShiftCondition)
        }
      >
        <SelectItem key="is_true" value="is_true">
          Is True
        </SelectItem>
        <SelectItem key="is_false" value="is_false">
          Is False
        </SelectItem>
        <SelectItem key="truthy" value="truthy">
          Is Truthy
        </SelectItem>
        <SelectItem key="not_truthy" value="not_truthy">
          Is Not Truthy
        </SelectItem>
      </Select>
    )}
  </div>
);
export const ComparisonTypeForm = ({
  condition,
  onChange,
}: {
  condition: Partial<BaseRewardShiftCondition>;
  onChange: (condition: BaseRewardShiftCondition) => void;
}) => {
  if (!condition.type) return null;

  switch (condition.type) {
    case 'date':
      return <DateComparisonForm condition={{ type: 'date', ...condition }} onChange={onChange} />;
    case 'number':
      return (
        <NumberComparisonForm condition={{ type: 'number', ...condition }} onChange={onChange} />
      );
    case 'string':
      return (
        <StringComparisonForm condition={{ type: 'string', ...condition }} onChange={onChange} />
      );
    case 'boolean':
      return (
        <BooleanComparisonForm condition={{ type: 'boolean', ...condition }} onChange={onChange} />
      );
    default:
      return null;
  }
};
