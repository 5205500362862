import { Input, Select, SelectItem } from '@nextui-org/react';
import {
  type BaseRewardConfigData,
  type BaseRewardProfileConfigData,
  type BaseRewardUserCondition,
  type RewardProfileDateField,
  RewardProfileDateFields,
  type RewardProfileStringField,
  RewardProfileStringFields,
} from '@perry/shared-types';
import React from 'react';

export type ProfileConditionFormProps = {
  configData: Partial<BaseRewardProfileConfigData>;
  setConfigData: React.Dispatch<React.SetStateAction<Partial<BaseRewardConfigData>>>;
};

const DateComparisonForm = ({
  condition,
  onChange,
}: {
  condition: Partial<BaseRewardUserCondition> & { type: 'date' };
  onChange: (condition: BaseRewardUserCondition) => void;
}) => (
  <div className="flex flex-col gap-4">
    <Select
      label="Field"
      selectedKeys={condition.field ? [condition.field] : undefined}
      value={condition.field}
      onChange={(e) =>
        onChange({
          ...condition,
          field: e.target.value as RewardProfileDateField,
        } as BaseRewardUserCondition)
      }
    >
      {RewardProfileDateFields.map((field) => (
        <SelectItem key={field} value={field}>
          {field}
        </SelectItem>
      ))}
    </Select>

    {condition.field && (
      <Select
        label="Comparison Type"
        value={condition.comparisonType}
        selectedKeys={condition.comparisonType ? [condition.comparisonType] : undefined}
        onChange={(e) => {
          if (!condition.field) return;
          const comparisonType = e.target.value;
          let newCondition: BaseRewardUserCondition;

          switch (comparisonType) {
            case 'same_date':
              newCondition = {
                ...condition,
                field: condition.field,
                comparisonType,
                comparedToData: 'today',
              };
              break;
            default:
              throw new Error(`Unhandled comparison type: ${comparisonType}`);
          }
          onChange(newCondition as BaseRewardUserCondition);
        }}
      >
        <SelectItem key="same_date" value="same_date">
          Is Today
        </SelectItem>
      </Select>
    )}
  </div>
);
const StringComparisonForm = ({
  condition,
  onChange,
}: {
  condition: Partial<BaseRewardUserCondition> & { type: 'string' };
  onChange: (condition: BaseRewardUserCondition) => void;
}) => (
  <div className="flex flex-col gap-4">
    <Select
      label="Field"
      value={condition.field}
      selectedKeys={condition.field ? [condition.field] : undefined}
      onChange={(e) =>
        onChange({
          ...condition,
          field: e.target.value as RewardProfileStringField,
        } as BaseRewardUserCondition)
      }
    >
      {RewardProfileStringFields.map((field) => (
        <SelectItem key={field} value={field}>
          {field}
        </SelectItem>
      ))}
    </Select>

    {condition.field && (
      <Select
        label="Comparison Type"
        value={condition.comparisonType}
        selectedKeys={condition.comparisonType ? [condition.comparisonType] : undefined}
        onChange={(e) => {
          const comparisonType = e.target.value;
          let newCondition: BaseRewardUserCondition;
          if (!condition.field) return;
          switch (comparisonType) {
            case 'eq':
            case 'neq':
            case 'contains':
            case 'not_contains':
              newCondition = {
                ...condition,
                field: condition.field,
                comparisonType,
                comparedToValue: '',
              };
              break;
            case 'is_one_of':
            case 'is_not_one_of':
            case 'contains_one_of':
            case 'not_contains_one_of':
              newCondition = {
                ...condition,
                field: condition.field,
                comparisonType,
                comparedToValue: [],
              };
              break;
            default:
              throw new Error(`Unhandled comparison type: ${comparisonType}`);
          }
          onChange(newCondition as BaseRewardUserCondition);
        }}
      >
        <SelectItem key="eq" value="eq">
          Equals
        </SelectItem>
        <SelectItem key="neq" value="neq">
          Does not equal
        </SelectItem>
        <SelectItem key="contains" value="contains">
          Contains
        </SelectItem>
        <SelectItem key="not_contains" value="not_contains">
          Does not contain
        </SelectItem>
        <SelectItem key="is_one_of" value="is_one_of">
          Is one of
        </SelectItem>
        <SelectItem key="is_not_one_of" value="is_not_one_of">
          Is not one of
        </SelectItem>
        <SelectItem key="contains_one_of" value="contains_one_of">
          Contains one of
        </SelectItem>
        <SelectItem key="not_contains_one_of" value="not_contains_one_of">
          Does not contain one of
        </SelectItem>
      </Select>
    )}

    {condition.comparisonType &&
      ['eq', 'neq', 'contains', 'not_contains'].includes(condition.comparisonType) && (
        <Input
          label="Compared Value"
          value={condition.comparedToValue?.toString() ?? ''}
          onChange={(e) =>
            onChange({
              ...condition,
              comparedToValue: e.target.value,
            } as BaseRewardUserCondition)
          }
        />
      )}

    {condition.comparisonType &&
      ['is_one_of', 'is_not_one_of', 'contains_one_of', 'not_contains_one_of'].includes(
        condition.comparisonType,
      ) && (
        <Input
          label="Compared Values (comma-separated)"
          value={
            Array.isArray(condition.comparedToValue) ? condition.comparedToValue.join(',') : ''
          }
          onChange={(e) =>
            onChange({
              ...condition,
              comparedToValue: e.target.value.split(',').map((v) => v.trim()),
            } as BaseRewardUserCondition)
          }
        />
      )}
  </div>
);
export const ProfileConditionForm = ({ configData, setConfigData }: ProfileConditionFormProps) => {
  const [partialCondition, setPartialCondition] = React.useState<Partial<BaseRewardUserCondition>>(
    configData.condition ?? {},
  );

  React.useEffect(() => {
    setConfigData((cd) => ({
      ...cd,
      type: 'user',
      condition: partialCondition as BaseRewardUserCondition,
    }));
  }, [partialCondition, setConfigData]);

  React.useEffect(() => {
    if (!partialCondition.type) {
      setPartialCondition(configData.condition ?? {});
    }
  }, [configData.condition, partialCondition.type]);

  return (
    <div className="flex flex-col gap-4 w-[50%]">
      <Select
        label="Field Type"
        selectedKeys={partialCondition.type ? [partialCondition.type] : undefined}
        value={partialCondition.type ?? ''}
        onChange={(e) =>
          setPartialCondition({ type: e.target.value as BaseRewardUserCondition['type'] })
        }
        className="w-full"
      >
        <SelectItem key="date" value="date">
          Date
        </SelectItem>
        <SelectItem key="string" value="string">
          String
        </SelectItem>
      </Select>

      {partialCondition.type === 'date' && (
        <DateComparisonForm
          condition={{ type: 'date', ...partialCondition }}
          onChange={setPartialCondition}
        />
      )}

      {partialCondition.type === 'string' && (
        <StringComparisonForm
          condition={{ type: 'string', ...partialCondition }}
          onChange={setPartialCondition}
        />
      )}
    </div>
  );
};
