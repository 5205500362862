"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SpotRewardError = void 0;
var SpotRewardError;
(function (SpotRewardError) {
    SpotRewardError["SPOT_REWARD_NOT_FOUND"] = "SPOT_REWARD_NOT_FOUND";
    SpotRewardError["EXCEEDS_MAX_SINGLE_SPOT_REWARD_AMOUNT"] = "EXCEEDS_MAX_SINGLE_SPOT_REWARD_AMOUNT";
    SpotRewardError["EXCEEDS_MAX_SPOT_REWARD_AMOUNT_FOR_AGENCY"] = "EXCEEDS_MAX_SPOT_REWARD_AMOUNT_FOR_AGENCY";
    SpotRewardError["EXCEEDS_MAX_REMAINING_AMOUNT_FOR_LOCATION"] = "EXCEEDS_MAX_REMAINING_AMOUNT_FOR_LOCATION";
    SpotRewardError["EXCEEDS_MAX_SPOT_REWARD_AMOUNT_FOR_LOCATION"] = "EXCEEDS_MAX_SPOT_REWARD_AMOUNT_FOR_LOCATION";
    SpotRewardError["EXCEEDS_MAX_REMAINING_AMOUNT_FOR_ADMIN"] = "EXCEEDS_MAX_REMAINING_AMOUNT_FOR_ADMIN";
    SpotRewardError["EXCEEDS_MAX_SPOT_REWARD_AMOUNT_FOR_ADMIN"] = "EXCEEDS_MAX_SPOT_REWARD_AMOUNT_FOR_ADMIN";
})(SpotRewardError || (exports.SpotRewardError = SpotRewardError = {}));
