"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ReferralSteps = void 0;
/**
 * @deprecated - This should move to a database enum
 */
var ReferralSteps;
(function (ReferralSteps) {
    ReferralSteps["SUBMITTED"] = "SUBMITTED";
    ReferralSteps["HIRED"] = "HIRED";
    ReferralSteps["FIRST_SHIFT"] = "FIRST_SHIFT";
    ReferralSteps["MILESTONE"] = "MILESTONE";
    ReferralSteps["APPLICATION_COMPLETED"] = "APPLICATION_COMPLETED";
    /**
     * @deprecated
     */
    ReferralSteps["CREATED"] = "CREATED";
    /**
     * @deprecated
     */
    ReferralSteps["CONTACTED"] = "CONTACTED";
    /**
     * @deprecated
     */
    ReferralSteps["INTERVIEW_SCHEDULED"] = "INTERVIEW_SCHEDULED";
    /**
     * @deprecated
     */
    ReferralSteps["OFFER_EXTENDED"] = "OFFER_EXTENDED";
    /**
     * @deprecated
     */
    ReferralSteps["ORIENTATION_COMPLETE"] = "ORIENTATION_COMPLETE";
    /**
     * @deprecated
     */
    ReferralSteps["WORK_STARTED"] = "WORK_STARTED";
    /**
     * @deprecated
     */
    ReferralSteps["FORTY_FIVE_HOURS_WORKED"] = "FORTY_FIVE_HOURS_WORKED";
    /**
     * @deprecated
     */
    ReferralSteps["NINETY_HOURS_WORKED"] = "NINETY_HOURS_WORKED";
    /**
     * @deprecated
     */
    ReferralSteps["SIX_MONTHS_WORKED"] = "SIX_MONTHS_WORKED";
    /**
     * @deprecated
     */
    ReferralSteps["KEEP_WARM"] = "KEEP_WARM";
    /**
     * @deprecated
     */
    ReferralSteps["APP_DENIED"] = "APP_DENIED";
    /**
     * @deprecated
     */
    ReferralSteps["LINK_SHARED"] = "LINK_SHARED";
    /**
     * @deprecated
     */
    ReferralSteps["APPLICATION_SUBMITTED"] = "APPLICATION_SUBMITTED";
    /**
     * @deprecated
     */
    ReferralSteps["SIGNED_OFFER_LETTER"] = "SIGNED_OFFER_LETTER";
    /**
     * @deprecated
     */
    ReferralSteps["ONBOARDING_PAPERWORK_COMPLETED"] = "ONBOARDING_PAPERWORK_COMPLETED";
    /**
     * @deprecated
     */
    ReferralSteps["THREE_MONTHS_WORKED"] = "THREE_MONTHS_WORKED";
    /**
     * @deprecated
     */
    ReferralSteps["TWELVE_MONTHS_WORKED"] = "TWELVE_MONTHS_WORKED";
})(ReferralSteps || (exports.ReferralSteps = ReferralSteps = {}));
