"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AgencyAdminLoginError = exports.PasswordLinkError = void 0;
var PasswordLinkError;
(function (PasswordLinkError) {
    PasswordLinkError["PASSWORD_LINK_INVALID"] = "PASSWORD_LINK_INVALID";
    PasswordLinkError["PASSWORD_ALREADY_SET"] = "PASSWORD_ALREADY_SET";
    PasswordLinkError["PASSWORD_SETUP_ERROR"] = "PASSWORD_SETUP_ERROR";
})(PasswordLinkError || (exports.PasswordLinkError = PasswordLinkError = {}));
var AgencyAdminLoginError;
(function (AgencyAdminLoginError) {
    AgencyAdminLoginError["PASSWORD_INCORRECT"] = "PASSWORD_INCORRECT";
    AgencyAdminLoginError["AGENCY_DEACTIVATED"] = "AGENCY_DEACTIVATED";
    AgencyAdminLoginError["PASSWORD_NOT_SET"] = "PASSWORD_NOT_SET";
})(AgencyAdminLoginError || (exports.AgencyAdminLoginError = AgencyAdminLoginError = {}));
