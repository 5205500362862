import { Search } from '@mui/icons-material';
import { Checkbox } from '@mui/material';
import {
  Button,
  getKeyValue,
  Input,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  Spinner,
  Table,
  TableBody,
  TableCell,
  TableColumn,
  TableHeader,
  TableRow,
} from '@nextui-org/react';
import { useInfiniteScroll } from '@nextui-org/use-infinite-scroll';
import { templatesColumns } from '@pages/Templates/utils';
import { createRewardForAgency, getTemplates } from '@services/templates';
import type { TemplateConfig, TemplateType } from '@services/templates/types';
import React, { type Key } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAsyncList } from 'react-stately';
import { toast } from 'react-toastify';

type TemplatesTableProps = {
  type: TemplateType;
  selectedTemplate: number | null;
  setSelectedTemplate: (id: number | null) => void;
};

type TemplateTableRowProps = {
  templateConfig: TemplateConfig;
  selectedTemplate: number | null;
  setSelectedTemplate: (id: number | null) => void;
  columnKey: Key;
};

const TemplateTableRow = ({
  templateConfig,
  selectedTemplate,
  setSelectedTemplate,
  columnKey,
}: TemplateTableRowProps) => {
  switch (columnKey) {
    case 'actions':
      return (
        <Checkbox
          checked={selectedTemplate === templateConfig.id}
          onChange={() => setSelectedTemplate(templateConfig.id)}
        />
      );

    default:
      return getKeyValue(templateConfig, columnKey.toString());
  }
};

const TemplatesTable = ({ type, selectedTemplate, setSelectedTemplate }: TemplatesTableProps) => {
  const [filter, setFilter] = React.useState('');
  const [page, setPage] = React.useState(1);
  const [hasMore, setHasMore] = React.useState(true);

  const list = useAsyncList<TemplateConfig>({
    load: async () => {
      const res = await getTemplates(type, page, filter);
      setHasMore(res.items.length === 20); // Assuming 20 is the page size
      return {
        items: res.items,
      };
    },
  });

  const [loaderRef, scrollerRef] = useInfiniteScroll({
    hasMore,
    onLoadMore: () => {
      setPage((prev) => prev + 1);
      list.loadMore();
    },
  });

  React.useEffect(() => {
    list.reload();
    setPage(1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [type, filter]);

  return (
    <div className="h-full overflow-auto gap-2">
      <Input
        aria-label="Search"
        size="sm"
        startContent={<Search />}
        onChange={(e) => setFilter(e.target.value)}
        placeholder="Search..."
        className="w-72"
      />
      <Table
        baseRef={scrollerRef}
        color="secondary"
        selectionMode="single"
        aria-label="Templates table"
        onSelectionChange={(e) => {
          if (e === 'all') setSelectedTemplate(null);
          else
            setSelectedTemplate(
              e.keys().next().value ? parseInt(e.keys().next().value.toString()) : null,
            );
        }}
        selectedKeys={[selectedTemplate?.toString() ?? '']}
        isStriped
        shadow="md"
        bottomContent={
          hasMore ? (
            <div className="flex justify-center w-full">
              <Spinner ref={loaderRef} color="secondary" />
            </div>
          ) : null
        }
        classNames={{
          base: 'h-full overflow-auto p-3',
          table: 'h-full',
        }}
      >
        <TableHeader columns={templatesColumns.filter((c) => c.key !== 'actions')}>
          {(column) => (
            <TableColumn className="text-white bg-purple-500" key={column.key}>
              {column.label}
            </TableColumn>
          )}
        </TableHeader>
        <TableBody
          key={selectedTemplate}
          items={list.items}
          emptyContent={'No rows to display.'}
          loadingContent={<Spinner color="secondary" />}
        >
          {(item: TemplateConfig) => (
            <TableRow key={item.id}>
              {(columnKey) => (
                <TableCell>
                  <TemplateTableRow
                    templateConfig={item}
                    selectedTemplate={selectedTemplate}
                    setSelectedTemplate={setSelectedTemplate}
                    columnKey={columnKey}
                  />
                </TableCell>
              )}
            </TableRow>
          )}
        </TableBody>
      </Table>
    </div>
  );
};

type CreateRewardModalProps = {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  agencyPublicId: string;
  agencyId: number;
};

export const CreateRewardModal = ({
  isOpen,
  setIsOpen,
  agencyPublicId,
  agencyId,
}: CreateRewardModalProps) => {
  const [selectedTemplate, setSelectedTemplate] = React.useState<number | null>(null);
  const navigate = useNavigate();
  const onCreate = React.useCallback(
    async (id?: number) => {
      const response = await createRewardForAgency(agencyPublicId, id);
      if (response.rewardId) {
        toast.success('Reward created successfully');
        navigate(`/agencies/${agencyId}/rewards/${response.rewardId}`);
        setIsOpen(false);
      } else {
        toast.error('Failed to create reward');
      }
    },
    [agencyId, agencyPublicId, navigate, setIsOpen],
  );

  return (
    <Modal isOpen={isOpen} onClose={() => setIsOpen(false)}>
      <ModalContent style={{ minWidth: '80%', minHeight: '80%' }}>
        <ModalHeader>
          <h1>Choose from template below</h1>
        </ModalHeader>
        <ModalBody>
          <TemplatesTable
            type="reward"
            selectedTemplate={selectedTemplate}
            setSelectedTemplate={setSelectedTemplate}
          />
        </ModalBody>
        <ModalFooter>
          <Button onClick={() => onCreate()} color="secondary">
            Create from scratch
          </Button>
          <Button
            disabled={selectedTemplate === null}
            onClick={() => selectedTemplate && onCreate(selectedTemplate)}
          >
            Create from template
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
