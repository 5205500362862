import { Modal, ModalBody, ModalContent } from '@nextui-org/modal';
import {
  Spinner,
  Table,
  TableBody,
  TableCell,
  TableColumn,
  TableHeader,
  TableRow,
} from '@nextui-org/react';
import type { IntegrationField } from '@services/processingStatus/types';
import dayjs from 'dayjs';
import { integrationFieldColumns } from '../utils';

type FieldsModalProps = {
  open: boolean;
  setOpen: (open: boolean) => void;
  fields: IntegrationField[];
  isLoading: boolean;
};

export const FieldsModal = ({ open, setOpen, fields, isLoading }: FieldsModalProps) => (
  <Modal isOpen={open} onClose={() => setOpen(false)} size="5xl">
    <ModalContent onClick={(e) => e.stopPropagation()}>
      <ModalBody>
        <div className="flex flex-col w-full h-full p-6 rounded">
          <div className="flex items-center justify-center w-full py-8">
            <Table
              aria-label="Integration Fields table"
              isStriped
              shadow="md"
              bottomContent={null}
              classNames={{
                base: 'max-h-[70vh] overflow-auto p-3',
                table: 'max-h-[50vh]',
              }}
            >
              <TableHeader columns={integrationFieldColumns}>
                {(column) => (
                  <TableColumn className="text-white bg-purple-500 text-center" key={column.key}>
                    {column.title}
                  </TableColumn>
                )}
              </TableHeader>
              <TableBody
                isLoading={isLoading}
                items={fields}
                loadingContent={<Spinner color="secondary" />}
                emptyContent={'No rows to display.'}
              >
                {(item: IntegrationField) => (
                  <TableRow key={item.id}>
                    <TableCell className="text-center">{item.name}</TableCell>
                    <TableCell className="text-center">{item.dataType}</TableCell>
                    <TableCell className="text-center">{item.isRequired ? 'Yes' : 'No'}</TableCell>
                    <TableCell className="text-center">
                      {item.isDeprecated ? 'Yes' : 'No'}
                    </TableCell>
                    <TableCell className="text-center">
                      {item.lastSeenAt ? dayjs(item.lastSeenAt).format('MMM D, YYYY') : 'N/A'}
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </div>
        </div>
      </ModalBody>
    </ModalContent>
  </Modal>
);
