import type { AgencyProfileFilter } from '@perry/shared-types';
import { apiRoutes } from '@services/constants';
import * as fetch from '@utils/fetch';

export const getAgencyProfileFilterByAgencyId = async (
  agencyId: number,
): Promise<AgencyProfileFilter> => {
  const response = await fetch.get<
    { status: 'OK'; data: AgencyProfileFilter } | { code: number; error?: string }
  >(apiRoutes.agencyProfileFilter.getAgencyProfileFilterByAgencyId(agencyId));

  if ('code' in response) {
    throw new Error(response.error);
  }

  return response.data;
};

export const createAgencyProfileFilter = async (
  agencyId: number,
  filter: string,
): Promise<AgencyProfileFilter> => {
  const response = await fetch.post<
    Pick<AgencyProfileFilter, 'agencyId' | 'filter'>,
    { status: 'OK'; data: AgencyProfileFilter } | { code: number; error?: string }
  >(apiRoutes.agencyProfileFilter.createAgencyProfileFilter, {
    agencyId,
    filter,
  });

  if ('code' in response) {
    throw new Error(response.error);
  }

  return response.data;
};

export const updateAgencyProfileFilter = async (
  id: number,
  data: Partial<Omit<AgencyProfileFilter, 'id' | 'createdAt' | 'updatedAt'>>,
): Promise<AgencyProfileFilter> => {
  const response = await fetch.put<
    typeof data,
    { status: 'ok'; data: AgencyProfileFilter } | { code: number; error?: string }
  >(apiRoutes.agencyProfileFilter.updateAgencyProfileFilter(id), data);

  if ('code' in response) {
    throw new Error(response.error);
  }

  return response.data;
};
