import { Card, CardBody, CardHeader, Input, Select, SelectItem } from '@nextui-org/react';
import type { BaseMultiplierConfig } from '@perry/shared-types';

type MultiplierConfigDataCardProps = {
  multiplierConfig: BaseMultiplierConfig | null;
  setMultiplierConfig: (config?: BaseMultiplierConfig | null) => void;
};

export const MultiplierConfigDataCard = ({
  multiplierConfig,
  setMultiplierConfig,
}: MultiplierConfigDataCardProps) => (
  <Card className="p-2 pt-0">
    <CardHeader>
      <div className="flex flex-row justify-between w-full items-center">
        <span className="text-lg font-bold">Points Multiplier</span>
      </div>
    </CardHeader>
    <CardBody>
      <div className="flex flex-col gap-2">
        <Select
          label="Multiplier Type"
          value={multiplierConfig?.type ?? 'none'}
          selectedKeys={multiplierConfig ? [multiplierConfig.type] : ['none']}
          onChange={(e) => {
            const value = e.target.value;
            if (value === 'none') return setMultiplierConfig(null);
            else if (value === 'multiplier') {
              return setMultiplierConfig({ type: 'multiplier', multiplier: 1 });
            } else if (value === 'custom') {
              return setMultiplierConfig({ type: 'custom', values: [] });
            }
          }}
          className="w-full"
        >
          <SelectItem key="none" value="none">
            None
          </SelectItem>
          <SelectItem key="multiplier" value="multiplier">
            Streak Multiplier
          </SelectItem>
          {/* <SelectItem key="custom" value="custom"> todo: implement
              Custom
            </SelectItem> */}
        </Select>
        {multiplierConfig?.type === 'multiplier' && (
          <Input
            type="number"
            label="Multiplier value"
            value={multiplierConfig.multiplier.toString()}
            onChange={(e) =>
              setMultiplierConfig({ type: 'multiplier', multiplier: parseInt(e.target.value) })
            }
          />
        )}
      </div>
    </CardBody>
  </Card>
);
