import { Delete } from '@mui/icons-material';
import { Button, ButtonGroup, Card, cn, Divider } from '@nextui-org/react';
import type { Filter, LogicalFilter, LogicalOperator } from '@perry/query-builder';
import { Fragment } from 'react/jsx-runtime';
import { OPERATORS_BY_TYPE, TYPE_DEFAULT_VALUES } from './constants';
import QueryField from './QueryField';
import type { Field } from './types';

type Props = {
  value: LogicalFilter<any>;
  onChange?: (value: LogicalFilter<any>) => void;
  fields: Field[];
  className?: string;
  onRemoveClick?: () => void;
};

const LogicalField = ({ value, onChange, fields, className, onRemoveClick }: Props) => {
  const handleAddFieldClick = () => {
    const field = fields[0];

    if (!field) {
      return;
    }

    onChange?.({
      ...value,
      filters: [
        ...value.filters,
        {
          type: field.type,
          field: field.name,
          operator: OPERATORS_BY_TYPE[field.type][0],
          value: TYPE_DEFAULT_VALUES[field.type],
        } as any,
      ],
    });
  };

  const handleAddGroupClick = () => {
    onChange?.({
      ...value,
      filters: [...value.filters, { type: 'logical', operator: 'and', filters: [] }],
    });
  };

  const handleChangeOperator = (operator: LogicalOperator) => {
    onChange?.({
      ...value,
      operator,
    });
  };

  return (
    <div className={cn('flex flex-col gap-2', className)}>
      <div className="flex flex-row items-center justify-between">
        <ButtonGroup>
          <Button
            color="secondary"
            variant={value?.operator === 'and' ? 'solid' : 'flat'}
            onClick={() => handleChangeOperator('and')}
          >
            AND
          </Button>
          <Button
            color="secondary"
            variant={value?.operator === 'or' ? 'solid' : 'flat'}
            onClick={() => handleChangeOperator('or')}
          >
            OR
          </Button>
        </ButtonGroup>
        <div className="flex flex-row items-center gap-2">
          <Button color="secondary" onClick={handleAddFieldClick}>
            Add Field
          </Button>
          <Button color="secondary" onClick={handleAddGroupClick}>
            Add Group
          </Button>
          <Button color="danger" variant="flat" isIconOnly onClick={onRemoveClick}>
            <Delete />
          </Button>
        </div>
      </div>
      {value.filters.length > 0 && (
        <Card>
          {value.filters.map((field, index) => {
            const handleChange = (newFieldValue: Filter<any>) => {
              onChange?.({
                ...value,
                filters: value?.filters.map((f, i) => (i === index ? newFieldValue : f)),
              });
            };

            const handleRemove = () => {
              onChange?.({
                ...value,
                filters: value?.filters.filter((_, i) => i !== index),
              });
            };

            if (field.type === 'logical') {
              return (
                <Fragment key={index}>
                  <div className="p-2">
                    <LogicalField
                      fields={fields}
                      value={field}
                      onChange={handleChange}
                      onRemoveClick={handleRemove}
                    />
                  </div>
                  {index < value?.filters.length - 1 && <Divider />}
                </Fragment>
              );
            }

            return (
              <Fragment key={index}>
                <QueryField
                  className="p-2"
                  fields={fields}
                  value={field}
                  onChange={handleChange}
                  onRemoveClick={handleRemove}
                />
                {index < value?.filters.length - 1 && <Divider />}
              </Fragment>
            );
          })}
        </Card>
      )}
    </div>
  );
};

export default LogicalField;
