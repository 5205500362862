import { Select, SelectItem } from '@nextui-org/react';
import type {
  BaseRewardConfigData,
  BaseRewardShiftCondition,
  BaseRewardVisitConfigData,
} from '@perry/shared-types';
import React from 'react';
import { ComparisonTypeForm } from './ComparisonTypeForm';

type ShiftConditionFormProps = {
  configData: Partial<BaseRewardVisitConfigData>;
  setConfigData: React.Dispatch<React.SetStateAction<Partial<BaseRewardConfigData>>>;
};

export const ShiftConditionForm = ({ configData, setConfigData }: ShiftConditionFormProps) => {
  const [partialCondition, setPartialCondition] = React.useState<Partial<BaseRewardShiftCondition>>(
    configData.condition ?? {},
  );

  React.useEffect(() => {
    setConfigData((cd) => ({
      ...cd,
      type: 'shift',
      condition: partialCondition as BaseRewardShiftCondition,
    }));
  }, [partialCondition, setConfigData]);

  React.useEffect(() => {
    if (!partialCondition.type) {
      setPartialCondition(configData.condition ?? {});
    }
  }, [configData.condition, partialCondition.type]);

  return (
    <div className="flex flex-col gap-4 w-[50%]">
      <Select
        selectedKeys={partialCondition.type ? [partialCondition.type] : undefined}
        label="Field Type"
        value={partialCondition.type ?? ''}
        onChange={(e) =>
          setPartialCondition({ type: e.target.value as BaseRewardShiftCondition['type'] })
        }
        className="w-full"
      >
        <SelectItem key="date" value="date">
          Date
        </SelectItem>
        <SelectItem key="number" value="number">
          Number
        </SelectItem>
        <SelectItem key="boolean" value="boolean">
          True/False
        </SelectItem>
        <SelectItem key="string" value="string">
          String (character values)
        </SelectItem>
      </Select>

      {partialCondition.type && (
        <ComparisonTypeForm condition={partialCondition} onChange={setPartialCondition} />
      )}
    </div>
  );
};
