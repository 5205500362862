import type {
  BaseCreateMissionTemplateParams,
  BaseCreateRewardConfigParams,
  BaseCreateRewardTemplateParams,
  BaseMissionConfigWithRewardConfig,
} from '@perry/shared-types';
import { apiRoutes } from '@services/constants';
import { getToken } from '@utils/auth';
import * as fetch from '@utils/fetch';
import type {
  CreateAgencyMissionResponse,
  CreateAgencyRewardResponse,
  GetMissionConfigResponse,
  GetMissionTemplateResponse,
  GetRewardConfigResponse,
  GetRewardTemplateResponse,
  ListAgencyMissionsResponse,
  ListAgencyRewardsResponse,
  ListTemplatesResponse,
  SaveMissionConfigResponse,
  SaveMissionTemplateResponse,
  SaveRewardConfigResponse,
  SaveRewardTemplateResponse,
} from './types';

export const getTemplates = (
  type?: string,
  page?: number,
  filter?: string,
): Promise<ListTemplatesResponse> =>
  fetch.get<ListTemplatesResponse>(
    apiRoutes.templates,
    {
      ...(type && { type }),
      page: page?.toString() ?? '',
      limit: '25',
      filter: filter ? filter.toLowerCase() : '',
    },
    {
      headers: { Authorization: `Bearer ${getToken()}` },
    },
  );

export const getRewardsForAgency = (agencyId: string): Promise<ListAgencyRewardsResponse> =>
  fetch.get<ListAgencyRewardsResponse>(
    `${apiRoutes.rewards}/get-agency-rewards/${agencyId}`,
    {},
    {
      headers: { Authorization: `Bearer ${getToken()}` },
    },
  );

export const getMissionsForAgency = (agencyId: string): Promise<ListAgencyMissionsResponse> =>
  fetch.get<ListAgencyMissionsResponse>(
    `${apiRoutes.rewards}/get-agency-missions/${agencyId}`,
    {},
    {
      headers: { Authorization: `Bearer ${getToken()}` },
    },
  );

export const createMissionForAgency = (
  agencyId: string,
  templateId?: number,
): Promise<CreateAgencyMissionResponse> =>
  fetch.post<{ templateId?: number; agencyId: string }, CreateAgencyMissionResponse>(
    `${apiRoutes.rewards}/create-agency-mission`,
    { templateId, agencyId },
    {
      headers: { Authorization: `Bearer ${getToken()}` },
    },
  );

export const createRewardForAgency = (
  agencyId: string,
  templateId?: number,
): Promise<CreateAgencyRewardResponse> =>
  fetch.post<{ templateId?: number; agencyId: string }, CreateAgencyRewardResponse>(
    `${apiRoutes.rewards}/create-agency-reward`,
    { templateId, agencyId },
    {
      headers: { Authorization: `Bearer ${getToken()}` },
    },
  );

export const getRewardConfig = (id: string): Promise<GetRewardConfigResponse> =>
  fetch.get<GetRewardConfigResponse>(
    `${apiRoutes.rewards}/get-agency-reward`,
    { configId: id },
    {
      headers: { Authorization: `Bearer ${getToken()}` },
    },
  );

export const getMissionConfig = (id: string): Promise<GetMissionConfigResponse> =>
  fetch.get<GetMissionConfigResponse>(
    `${apiRoutes.rewards}/get-agency-mission`,
    { configId: id },
    {
      headers: { Authorization: `Bearer ${getToken()}` },
    },
  );

export const getRewardTemplate = (id: string): Promise<GetRewardTemplateResponse> =>
  fetch.get<GetRewardTemplateResponse>(
    `${apiRoutes.templates}/reward-template/${id}`,
    {},
    {
      headers: { Authorization: `Bearer ${getToken()}` },
    },
  );

export const getMissionTemplate = (id: string): Promise<GetMissionTemplateResponse> =>
  fetch.get<GetMissionTemplateResponse>(
    `${apiRoutes.templates}/mission-template/${id}`,
    {},
    {
      headers: { Authorization: `Bearer ${getToken()}` },
    },
  );

export const createRewardTemplate = (
  createdTemplate: BaseCreateRewardTemplateParams,
): Promise<SaveRewardTemplateResponse> =>
  fetch.post<{ template: BaseCreateRewardTemplateParams }, SaveRewardTemplateResponse>(
    `${apiRoutes.templates}/create-reward-template`,
    { template: createdTemplate },
    {
      headers: { Authorization: `Bearer ${getToken()}` },
    },
  );

export const updateRewardTemplate = (
  id: number,
  updatedTemplate: BaseCreateRewardTemplateParams,
): Promise<SaveRewardTemplateResponse> =>
  fetch.post<{ template: BaseCreateRewardTemplateParams; id: number }, SaveRewardTemplateResponse>(
    `${apiRoutes.templates}/update-reward-template`,
    { template: updatedTemplate, id },
    {
      headers: { Authorization: `Bearer ${getToken()}` },
    },
  );

export const updateRewardConfig = (
  configId: number,
  config: BaseCreateRewardConfigParams,
): Promise<SaveRewardConfigResponse> =>
  fetch.post<{ config: BaseCreateRewardConfigParams; configId: number }, SaveRewardConfigResponse>(
    `${apiRoutes.rewards}/update-agency-reward`,
    { config, configId },
    {
      headers: { Authorization: `Bearer ${getToken()}` },
    },
  );

export const updateMissionConfig = (
  configId: number,
  config: BaseMissionConfigWithRewardConfig,
): Promise<SaveMissionConfigResponse> =>
  fetch.post<
    { config: BaseMissionConfigWithRewardConfig; configId: number },
    SaveMissionConfigResponse
  >(
    `${apiRoutes.rewards}/update-agency-mission`,
    { config, configId },
    {
      headers: { Authorization: `Bearer ${getToken()}` },
    },
  );

export const deleteRewardConfig = (
  configId: number,
  isMission: boolean,
): Promise<{ rewardId: number }> =>
  fetch.post<{ configId: number; isMission: boolean }, { rewardId: number }>(
    `${apiRoutes.rewards}/delete-agency-reward`,
    { configId, isMission },
    {
      headers: { Authorization: `Bearer ${getToken()}` },
    },
  );

export const createMissionTemplate = (
  createdTemplate: BaseCreateMissionTemplateParams,
): Promise<SaveMissionTemplateResponse> =>
  fetch.post<{ template: BaseCreateMissionTemplateParams }, SaveMissionTemplateResponse>(
    `${apiRoutes.templates}/create-mission-template`,
    { template: createdTemplate },
    {
      headers: { Authorization: `Bearer ${getToken()}` },
    },
  );

export const updateMissionTemplate = (
  id: number,
  updatedTemplate: BaseCreateMissionTemplateParams,
): Promise<SaveMissionTemplateResponse> =>
  fetch.post<
    { template: BaseCreateMissionTemplateParams; id: number },
    SaveMissionTemplateResponse
  >(
    `${apiRoutes.templates}/update-mission-template`,
    { template: updatedTemplate, id },
    {
      headers: { Authorization: `Bearer ${getToken()}` },
    },
  );
