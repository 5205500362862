"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AgencyAdminRole = void 0;
var AgencyAdminRole;
(function (AgencyAdminRole) {
    AgencyAdminRole["GLOBAL_ADMIN"] = "GLOBAL_ADMIN";
    AgencyAdminRole["ADMIN"] = "ADMIN";
    AgencyAdminRole["RECRUITER"] = "RECRUITER";
    AgencyAdminRole["SUPER_ADMIN"] = "SUPER_ADMIN";
    AgencyAdminRole["STAFF"] = "STAFF";
    AgencyAdminRole["PREONBOARDING"] = "PREONBOARDING";
})(AgencyAdminRole || (exports.AgencyAdminRole = AgencyAdminRole = {}));
