import { CalendarDate } from '@internationalized/date';
import { Delete } from '@mui/icons-material';
import { Button, Checkbox, cn, DatePicker, Input, Select, SelectItem } from '@nextui-org/react';
import type {
  BooleanOperator,
  DateOperator,
  FieldFilter,
  NumberOperator,
  Operator,
  StringOperator,
} from '@perry/query-builder';
import { OPERATORS_BY_TYPE, TYPE_DEFAULT_VALUES } from './constants';
import type { Field } from './types';

type Props = {
  value: FieldFilter<any>;
  onChange?: (value: FieldFilter<any>) => void;
  onRemoveClick?: () => void;
  fields: Field[];
  className?: string;
};

const operatorLabels: Record<string, string> = {
  eq: 'Equals',
  neq: 'Not Equals',
  gt: 'Greater Than',
  gte: 'Greater Than Or Equal',
  lt: 'Less Than',
  lte: 'Less Than Or Equal',
  contains: 'Contains',
  startsWith: 'Starts With',
  endsWith: 'Ends With',
} satisfies {
  [operator in BooleanOperator | NumberOperator | StringOperator | DateOperator]: string;
};

const QueryField = ({ fields, value, onChange, onRemoveClick, className }: Props) => {
  const operators = OPERATORS_BY_TYPE[value.type];

  return (
    <div className={cn('flex flex-row items-center justify-between gap-2', className)}>
      <div className="flex flex-row items-center gap-2 grow">
        <Select
          className="max-w-[200px]"
          placeholder="Field"
          aria-label="Field"
          onChange={(e) => {
            onChange?.({
              field: e.target.value,
              type: fields.find((f) => f.name === e.target.value)?.type as any,
              operator: OPERATORS_BY_TYPE[
                fields.find((f) => f.name === e.target.value)?.type as any as Operator
              ][0] as any,
              value:
                TYPE_DEFAULT_VALUES[
                  fields.find((f) => f.name === e.target.value)?.type as any as Operator
                ],
            });
          }}
          selectedKeys={new Set<string>([value.field as string])}
        >
          {fields.map((field) => (
            <SelectItem key={field.name} value={field.name}>
              {field.label}
            </SelectItem>
          ))}
        </Select>
        <Select
          className="max-w-[200px]"
          aria-label="Operator"
          placeholder="Operator"
          selectedKeys={new Set<string>([value.operator])}
          onChange={(e) => {
            onChange?.({
              field: value.field,
              type: value.type,
              operator: e.target.value as any,
              value: value.value as any,
            });
          }}
        >
          {operators.map((operator) => (
            <SelectItem key={operator} value={operator}>
              {operatorLabels[operator] as any}
            </SelectItem>
          ))}
        </Select>
        {value.type === 'number' && (
          <Input
            type="number"
            className="max-w-[200px]"
            placeholder="Value"
            aria-label="Value"
            value={value.value as any as string}
            onChange={(e) =>
              onChange?.({
                ...value,
                value: e.target.value as any,
              })
            }
          />
        )}
        {value.type === 'string' && (
          <Input
            type="text"
            className="max-w-[200px]"
            placeholder="Value"
            aria-label="Value"
            value={value.value as any as string}
            onChange={(e) =>
              onChange?.({
                ...value,
                value: e.target.value as any,
              })
            }
          />
        )}
        {value.type === 'boolean' && (
          <Checkbox
            isSelected={value.value as any as boolean}
            onChange={(e) =>
              onChange?.({
                ...value,
                value: e.target.checked as any,
              })
            }
            aria-label="Value"
          >
            {value.value ? 'True' : 'False'}
          </Checkbox>
        )}
        {value.type === 'date' && (
          <DatePicker
            className="max-w-[200px]"
            aria-label="Date"
            value={
              new CalendarDate(
                value.value.getFullYear(),
                value.value.getMonth(),
                value.value.getDate(),
              )
            }
            onChange={(e: { day: number; month: number; year: number }) =>
              onChange?.({
                ...value,
                value: new Date(e.year, e.month, e.day),
              })
            }
          />
        )}
      </div>
      <Button color="danger" variant="flat" isIconOnly onClick={onRemoveClick}>
        <Delete />
      </Button>
    </div>
  );
};

export default QueryField;
