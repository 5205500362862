"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const zod_1 = require("zod");
const constants_1 = require("./constants");
const baseFilterSchema = zod_1.z.object({
    field: zod_1.z.string(),
});
const booleanFilterSchema = baseFilterSchema.extend({
    type: zod_1.z.literal('boolean'),
    operator: zod_1.z.enum(constants_1.BOOLEAN_OPERATORS),
    value: zod_1.z.boolean(),
});
const numberFilterSchema = baseFilterSchema.extend({
    type: zod_1.z.literal('number'),
    operator: zod_1.z.enum(constants_1.NUMBER_OPERATORS),
    value: zod_1.z.number(),
});
const stringFilterSchema = baseFilterSchema.extend({
    type: zod_1.z.literal('string'),
    operator: zod_1.z.enum(constants_1.STRING_OPERATORS),
    value: zod_1.z.string(),
    caseInsensitive: zod_1.z.boolean().optional(),
});
const dateFilterSchema = baseFilterSchema.extend({
    type: zod_1.z.literal('date'),
    operator: zod_1.z.enum(constants_1.DATE_OPERATORS),
    value: zod_1.z.date(),
});
const logicalFilterSchema = zod_1.z.object({
    type: zod_1.z.literal('logical'),
    operator: zod_1.z.enum(constants_1.LOGICAL_OPERATORS),
    filters: zod_1.z.array(zod_1.z.union([zod_1.z.lazy(() => filterSchema), zod_1.z.lazy(() => logicalFilterSchema)])),
});
const fieldFilterSchema = zod_1.z.union([
    booleanFilterSchema,
    numberFilterSchema,
    stringFilterSchema,
    dateFilterSchema,
]);
const filterSchema = zod_1.z.union([fieldFilterSchema, logicalFilterSchema]);
function parse(query) {
    if (typeof query === 'string') {
        return filterSchema.parse(JSON.parse(query));
    }
    return filterSchema.parse(query);
}
exports.default = parse;
