"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BooleanComparisonTypes = exports.StringComparisonTypes = exports.NumberComparisonTypes = exports.DateComparisonTypes = exports.RewardProfileTypingUtils = exports.RewardProfileDateFields = exports.RewardProfileStringFields = exports.RewardShiftTypingUtils = exports.RewardShiftStringFields = exports.RewardShiftBooleanFields = exports.RewardShiftNumberFields = exports.RewardShiftDateFields = exports.HolidayEnum = void 0;
var HolidayEnum;
(function (HolidayEnum) {
    HolidayEnum["NEW_YEAR"] = "NEW_YEAR";
    HolidayEnum["CHRISTMAS"] = "CHRISTMAS";
    HolidayEnum["JULLY_4TH"] = "JULLY_4TH";
    HolidayEnum["LABOR_DAY"] = "LABOR_DAY";
    HolidayEnum["COLUMBUS_DAY"] = "COLUMBUS_DAY";
    HolidayEnum["MEMORIAL_DAY"] = "MEMORIAL_DAY";
    HolidayEnum["THANKSGIVING_DAY"] = "THANKSGIVING_DAY";
    HolidayEnum["MARTIN_LUTHER_KING"] = "MARTIN_LUTHER_KING";
    HolidayEnum["HOLIDAY"] = "HOLIDAY";
    HolidayEnum["WEEKEND"] = "WEEKEND";
})(HolidayEnum || (exports.HolidayEnum = HolidayEnum = {}));
exports.RewardShiftDateFields = [
    'startDate',
    'endDate',
    'clockInDate',
    'clockOutDate',
    'visitSubmittedDate',
];
exports.RewardShiftNumberFields = [
    'clockInDistance',
    'clockOutDistance',
];
exports.RewardShiftBooleanFields = [
    'isMissedShift',
    'hasCertifiedNote',
    'visitNotesSubmittedSameDayIndicator',
    'completedVisitIndicator',
    'socVisitIndicator',
    'missedVisitIndicator',
    'preferredClockInMethod',
    'preferredClockOutMethod',
    'goodDocumentation',
    'cancelled',
];
exports.RewardShiftStringFields = [
    'payCode',
    'missedVisitReason',
    'earnedProductivityPoints',
    'scheduleStatus',
    'visitStatus',
    'clockInDayOfWeek',
    'eChartStatus',
];
var RewardShiftTypingUtils;
(function (RewardShiftTypingUtils) {
    RewardShiftTypingUtils.rewardShiftFieldIsDate = (field) => {
        return exports.RewardShiftDateFields.includes(field);
    };
    RewardShiftTypingUtils.rewardShiftFieldIsBoolean = (field) => {
        return exports.RewardShiftBooleanFields.includes(field);
    };
    RewardShiftTypingUtils.rewardShiftFieldIsNumber = (field) => {
        return exports.RewardShiftNumberFields.includes(field);
    };
    RewardShiftTypingUtils.rewardShiftFieldIsString = (field) => {
        return exports.RewardShiftStringFields.includes(field);
    };
})(RewardShiftTypingUtils || (exports.RewardShiftTypingUtils = RewardShiftTypingUtils = {}));
exports.RewardProfileStringFields = [
    'discipline',
    'employmentType',
    'caregiverCode',
];
exports.RewardProfileDateFields = [
    'onboardingDate',
    'birthday',
    'lastLoginDate',
];
var RewardProfileTypingUtils;
(function (RewardProfileTypingUtils) {
    RewardProfileTypingUtils.rewardProfileFieldIsString = (field) => {
        return exports.RewardProfileStringFields.includes(field);
    };
    RewardProfileTypingUtils.rewardProfileFieldIsDate = (field) => {
        return exports.RewardProfileDateFields.includes(field);
    };
})(RewardProfileTypingUtils || (exports.RewardProfileTypingUtils = RewardProfileTypingUtils = {}));
exports.DateComparisonTypes = [
    'in_threshold',
    'lte',
    'gte',
    'null',
    'not_null',
    'comparison_type',
    'is_holiday',
];
exports.NumberComparisonTypes = [
    'lte',
    'gte',
    'eq',
    'null',
    'not_null',
];
exports.StringComparisonTypes = [
    'eq',
    'neq',
    'contains',
    'not_contains',
    'null',
    'not_null',
    'is_one_of',
    'is_not_one_of',
    'contains_one_of',
    'not_contains_one_of',
];
exports.BooleanComparisonTypes = ['eq', 'null', 'not_null'];
