import { Card, CardBody, CardHeader, Input, Select, SelectItem } from '@nextui-org/react';
import type {
  BaseMissionRecurrenceConfig,
  BaseMissionRecurrenceRecurringConfig,
} from '@perry/shared-types';
import { DateTime } from 'luxon';

type MissionRecurrenceConfigDataCardProps = {
  missionRecurrenceConfig: BaseMissionRecurrenceConfig;
  setMissionRecurrenceConfig: (config: BaseMissionRecurrenceConfig) => void;
};

export const MissionRecurrenceConfigDataCard = ({
  missionRecurrenceConfig,
  setMissionRecurrenceConfig,
}: MissionRecurrenceConfigDataCardProps) => (
  <Card className="p-2 pt-0">
    <CardHeader>
      <div className="flex flex-row justify-between w-full items-center">
        <span className="text-lg font-bold">Mission Recurrence</span>
      </div>
    </CardHeader>
    <CardBody>
      <div className="flex flex-col gap-2">
        <Select
          label="Recurrence Type"
          value={missionRecurrenceConfig.type}
          selectedKeys={[missionRecurrenceConfig.type]}
          onChange={(e) => {
            const value = e.target.value;
            if (value === 'recurring') {
              setMissionRecurrenceConfig({
                type: 'recurring',
                frequency: 'daily',
              });
            } else if (value === 'one_time') {
              setMissionRecurrenceConfig({
                type: 'one_time',
                startDate: new Date(),
                endDate: new Date(),
              });
            } else if (value === 'on_previous_complete') {
              setMissionRecurrenceConfig({
                type: 'on_previous_complete',
                daysToComplete: 7,
              });
            }
          }}
        >
          <SelectItem key="recurring" value="recurring">
            Recurring
          </SelectItem>
          <SelectItem key="one_time" value="one_time">
            One Time
          </SelectItem>
          <SelectItem key="on_previous_complete" value="on_previous_complete">
            On Previous Complete
          </SelectItem>
        </Select>
        {missionRecurrenceConfig.type === 'recurring' && (
          <>
            <Select
              label="Frequency"
              value={(missionRecurrenceConfig as BaseMissionRecurrenceRecurringConfig).frequency}
              selectedKeys={[
                (missionRecurrenceConfig as BaseMissionRecurrenceRecurringConfig).frequency,
              ]}
              onChange={(e) => {
                const value = e.target.value;
                if (value === 'daily') {
                  setMissionRecurrenceConfig({
                    type: 'recurring',
                    frequency: 'daily',
                  });
                } else if (value === 'weekly') {
                  setMissionRecurrenceConfig({
                    type: 'recurring',
                    frequency: 'weekly',
                    dayOfWeek: 0,
                    hour: 0,
                    minute: 0,
                  });
                } else if (value === 'monthly') {
                  setMissionRecurrenceConfig({
                    type: 'recurring',
                    frequency: 'monthly',
                    dayOfMonth: 1,
                    hour: 0,
                    minute: 0,
                  });
                }
              }}
            >
              <SelectItem key="daily" value="daily">
                Daily
              </SelectItem>
              <SelectItem key="weekly" value="weekly">
                Weekly
              </SelectItem>
              <SelectItem key="monthly" value="monthly">
                Monthly
              </SelectItem>
            </Select>

            {(missionRecurrenceConfig as BaseMissionRecurrenceRecurringConfig).frequency ===
              'weekly' && (
              <Select
                label="What day does the mission start?"
                value={(missionRecurrenceConfig as any).dayOfWeek.toString()}
                selectedKeys={[(missionRecurrenceConfig as any).dayOfWeek.toString()]}
                onChange={(e) => {
                  setMissionRecurrenceConfig({
                    ...(missionRecurrenceConfig as any),
                    dayOfWeek: parseInt(e.target.value),
                  });
                }}
              >
                <SelectItem key="0" value="0">
                  Sunday
                </SelectItem>
                <SelectItem key="1" value="1">
                  Monday
                </SelectItem>
                <SelectItem key="2" value="2">
                  Tuesday
                </SelectItem>
                <SelectItem key="3" value="3">
                  Wednesday
                </SelectItem>
                <SelectItem key="4" value="4">
                  Thursday
                </SelectItem>
                <SelectItem key="5" value="5">
                  Friday
                </SelectItem>
                <SelectItem key="6" value="6">
                  Saturday
                </SelectItem>
              </Select>
            )}

            {(missionRecurrenceConfig as BaseMissionRecurrenceRecurringConfig).frequency ===
              'monthly' && (
              <Input
                type="number"
                label="Day of Month"
                min={1}
                max={31}
                value={(missionRecurrenceConfig as any).dayOfMonth.toString()}
                onChange={(e) => {
                  setMissionRecurrenceConfig({
                    ...(missionRecurrenceConfig as any),
                    dayOfMonth: parseInt(e.target.value),
                  });
                }}
              />
            )}

            {(missionRecurrenceConfig as BaseMissionRecurrenceRecurringConfig).frequency !==
              'daily' && (
              <div className="flex flex-row gap-2">
                <Input
                  type="number"
                  label="Hour"
                  min={0}
                  max={23}
                  value={(missionRecurrenceConfig as any).hour.toString()}
                  onChange={(e) => {
                    setMissionRecurrenceConfig({
                      ...(missionRecurrenceConfig as any),
                      hour: parseInt(e.target.value),
                    });
                  }}
                />
                <Input
                  type="number"
                  label="Minute"
                  min={0}
                  max={59}
                  value={(missionRecurrenceConfig as any).minute.toString()}
                  onChange={(e) => {
                    setMissionRecurrenceConfig({
                      ...(missionRecurrenceConfig as any),
                      minute: parseInt(e.target.value),
                    });
                  }}
                />
              </div>
            )}
          </>
        )}

        {missionRecurrenceConfig.type === 'one_time' && (
          <div className="flex flex-row gap-2">
            <Input
              type="datetime-local"
              label="Start Date"
              value={DateTime.fromJSDate(missionRecurrenceConfig.startDate).toFormat('yyyy-MM-dd')}
              onChange={(e) => {
                setMissionRecurrenceConfig({
                  ...missionRecurrenceConfig,
                  startDate: new Date(e.target.value),
                });
              }}
            />
            <Input
              type="datetime-local"
              label="End Date (Optional)"
              value={
                missionRecurrenceConfig.endDate
                  ? DateTime.fromJSDate(missionRecurrenceConfig.endDate).toFormat('yyyy-MM-dd')
                  : ''
              }
              onChange={(e) => {
                setMissionRecurrenceConfig({
                  ...missionRecurrenceConfig,
                  endDate: e.target.value ? new Date(e.target.value) : undefined,
                });
              }}
            />
          </div>
        )}
        {missionRecurrenceConfig.type === 'on_previous_complete' && (
          <Input
            type="number"
            label="Days to Complete"
            value={(missionRecurrenceConfig as any).daysToComplete.toString()}
            onChange={(e) => {
              setMissionRecurrenceConfig({
                ...missionRecurrenceConfig,
                daysToComplete: parseInt(e.target.value),
              });
            }}
          />
        )}
      </div>
    </CardBody>
  </Card>
);
