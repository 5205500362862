import {
  BOOLEAN_OPERATORS,
  DATE_OPERATORS,
  LOGICAL_OPERATORS,
  NUMBER_OPERATORS,
  STRING_OPERATORS,
  type Operator,
} from '@perry/query-builder';

export const OPERATORS_BY_TYPE: { [type in Operator]: string[] } = {
  boolean: [...BOOLEAN_OPERATORS],
  number: [...NUMBER_OPERATORS],
  string: [...STRING_OPERATORS],
  date: [...DATE_OPERATORS],
  logical: [...LOGICAL_OPERATORS],
};

export const TYPE_DEFAULT_VALUES: { [type in Operator]: any } = {
  boolean: false,
  number: 0,
  string: '',
  date: new Date(),
  logical: false,
};
