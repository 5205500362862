import { Info } from '@mui/icons-material';
import {
  getKeyValue,
  Spinner,
  Table,
  TableBody,
  TableCell,
  TableColumn,
  TableHeader,
  TableRow,
  Tooltip,
} from '@nextui-org/react';
import type { ProcessingStatus } from '@services/processingStatus/types';
import dayjs from 'dayjs';
import type { Key } from 'react';
import { useCallback } from 'react';
import { FieldsModal } from './components/FieldsModal';
import { useProcessingStatusPage } from './useProcessingStatusPage';
import { processingStatusColumns } from './utils';

export const ProcessingStatusPage = () => {
  const {
    processingStatus,
    integrationFields,
    isProcessingStatusLoading,
    isIntegrationFieldsLoading,
    handleSeeFields,
    open,
    setOpen,
  } = useProcessingStatusPage();

  const renderCell = useCallback(
    (data: ProcessingStatus, columnKey: Key) => {
      switch (columnKey) {
        case 'lastFailedProcessing':
        case 'lastSuccessfulProcessing':
        case 'lastRunAt':
          return getKeyValue(data, columnKey.toString())
            ? dayjs(getKeyValue(data, columnKey.toString())).format('MMM D, YYYY hh:mm a')
            : 'N/A';
        case 'fields':
          return (
            <div>
              <Tooltip content="Details">
                <Info
                  color="secondary"
                  style={{ cursor: 'pointer' }}
                  onClick={() => handleSeeFields(data.provider)}
                />
              </Tooltip>
            </div>
          );
        default:
          return getKeyValue(data, columnKey.toString());
      }
    },
    [handleSeeFields],
  );

  return (
    <div className="p-6">
      <h1 className="text-2xl font-bold mb-6">Processing Status</h1>
      <Table
        aria-label="Processing Status table"
        isStriped
        shadow="md"
        bottomContent={null}
        classNames={{
          base: 'max-h-[70vh] overflow-auto p-3',
          table: 'max-h-[50vh]',
        }}
      >
        <TableHeader columns={processingStatusColumns}>
          {(column) => (
            <TableColumn className="text-white bg-purple-500 text-center" key={column.key}>
              {column.title}
            </TableColumn>
          )}
        </TableHeader>
        <TableBody
          isLoading={isProcessingStatusLoading}
          items={processingStatus}
          loadingContent={<Spinner color="secondary" />}
          emptyContent={'No rows to display.'}
        >
          {(item: ProcessingStatus) => (
            <TableRow key={item.id}>
              {(columnKey) => (
                <TableCell className="text-center">{renderCell(item, columnKey)}</TableCell>
              )}
            </TableRow>
          )}
        </TableBody>
      </Table>
      <FieldsModal
        open={open}
        setOpen={setOpen}
        fields={integrationFields}
        isLoading={isIntegrationFieldsLoading}
      />
    </div>
  );
};
