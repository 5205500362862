"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.WeekdayNumbersMap = void 0;
exports.WeekdayNumbersMap = {
    Monday: 1,
    Tuesday: 2,
    Wednesday: 3,
    Thursday: 4,
    Friday: 5,
    Saturday: 6,
    Sunday: 7,
};
