export const processingStatusColumns = [
  {
    title: 'Provider',
    dataIndex: 'provider',
    key: 'provider',
  },
  {
    title: 'Data Type',
    dataIndex: 'dataType',
    key: 'dataType',
  },
  {
    title: 'Last Run',
    dataIndex: 'lastRunAt',
    key: 'lastRunAt',
  },
  {
    title: 'Last Success',
    dataIndex: 'lastSuccessfulProcessing',
    key: 'lastSuccessfulProcessing',
  },
  {
    title: 'Last Failed',
    dataIndex: 'lastFailedProcessing',
    key: 'lastFailedProcessing',
  },
  {
    title: 'Consecutive Failures',
    dataIndex: 'consecutiveFailures',
    key: 'consecutiveFailures',
  },
  {
    title: 'Fields',
    dataIndex: 'fields',
    key: 'fields',
  },
];

export const integrationFieldColumns = [
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
  },
  {
    title: 'Data Type',
    dataIndex: 'dataType',
    key: 'dataType',
  },
  {
    title: 'Is Required',
    dataIndex: 'isRequired',
    key: 'isRequired',
  },
  {
    title: 'Is Deprecated',
    dataIndex: 'isDeprecated',
    key: 'isDeprecated',
  },
  {
    title: 'Last Seen At',
    dataIndex: 'lastSeenAt',
    key: 'lastSeenAt',
  },
];
