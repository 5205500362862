import PageTitle from '@components/PageTitle';
import { QueryBuilder, type Field, type Filter } from '@components/QueryBuilder';
import { Button, Card, Checkbox, Divider, Skeleton } from '@nextui-org/react';
import { parse } from '@perry/query-builder';
import type { AgencyProfileFilter, Profile } from '@perry/shared-types';
import { getAgencyDetails } from '@services/agencies';
import type { AgencyDetailsObject } from '@services/agencies/types';
import {
  createAgencyProfileFilter,
  getAgencyProfileFilterByAgencyId,
  updateAgencyProfileFilter,
} from '@services/profile-filter';
import { Fragment, useEffect, useState, type ChangeEventHandler } from 'react';
import ReactJson from 'react-json-view';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

const profileFilterFields: Field[] = [
  { name: 'externalProfileId', label: 'External Profile ID', type: 'string' },
  { name: 'firstName', label: 'First Name', type: 'string' },
  { name: 'surname', label: 'Surname', type: 'string' },
  { name: 'phoneNumber', label: 'Phone Number', type: 'string' },
  { name: 'isActive', label: 'Is Active', type: 'boolean' },
  { name: 'title', label: 'Title', type: 'string' },
  { name: 'email', label: 'Email', type: 'string' },
  { name: 'pronouns', label: 'Pronouns', type: 'string' },
  { name: 'timezone', label: 'Timezone', type: 'string' },
  { name: 'birthday', label: 'Birthday', type: 'date' },
  { name: 'onboardingDate', label: 'Onboarding Date', type: 'date' },
  { name: 'offboardingDate', label: 'Offboarding Date', type: 'date' },
  { name: 'lastModifiedDateTime', label: 'Last Modified Date Time', type: 'date' },
  { name: 'language', label: 'Language', type: 'string' },
  { name: 'location', label: 'Location', type: 'string' },
  { name: 'team', label: 'Team', type: 'string' },
  { name: 'discipline', label: 'Discipline', type: 'string' },
  { name: 'employmentType', label: 'Employment Type', type: 'string' },
  { name: 'employeeEmrId', label: 'Employee EMR ID', type: 'string' },
  { name: 'expectedProductivityPoints', label: 'Expected Productivity Points', type: 'number' },
  { name: 'jobTitle', label: 'Job Title', type: 'string' },
  { name: 'totalPoints', label: 'Total Points', type: 'number' },
  { name: 'completedClasses', label: 'Completed Classes', type: 'number' },
  { name: 'totalClasses', label: 'Total Classes', type: 'number' },
  { name: 'registered', label: 'Registered', type: 'boolean' },
  { name: 'lastFeedVisit', label: 'Last Feed Visit', type: 'string' },
  { name: 'bankLinkToken', label: 'Bank Link Token', type: 'string' },
  { name: 'bankLinkID', label: 'Bank Link ID', type: 'string' },
  { name: 'bankLinked', label: 'Bank Linked', type: 'boolean' },
  { name: 'earnedThisWeek', label: 'Earned This Week', type: 'number' },
  { name: 'transactionsLocked', label: 'Transactions Locked', type: 'boolean' },
  { name: 'showBankingTab', label: 'Show Banking Tab', type: 'boolean' },
  { name: 'maxBalance', label: 'Max Balance', type: 'number' },
  { name: 'mainProfileId', label: 'Main Profile ID', type: 'string' },
  { name: 'jobClass', label: 'Job Class', type: 'string' },
];
profileFilterFields.sort((a, b) => a.name.localeCompare(b.name));

export const ProfileFilterPage = () => {
  const { id } = useParams();
  const [agency, setAgency] = useState<AgencyDetailsObject>();
  const [agencyProfileFilter, setAgencyProfileFilter] = useState<AgencyProfileFilter | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isDirty, setIsDirty] = useState(false);
  const [filter, setFilter] = useState<Filter<Profile>>();

  useEffect(() => {
    if (!id) return;

    setIsLoading(true);

    Promise.allSettled([getAgencyDetails(id), getAgencyProfileFilterByAgencyId(Number(id))])
      .then(([agencyDetailsResponse, agencyProfileFilterResponse]) => {
        if (agencyDetailsResponse.status === 'fulfilled') {
          setAgency(agencyDetailsResponse.value);
        }

        if (agencyProfileFilterResponse.status === 'fulfilled') {
          setAgencyProfileFilter(agencyProfileFilterResponse.value);
        } else {
          setAgencyProfileFilter(null);
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [id]);

  const initFilter = () => {
    setFilter({
      type: 'logical',
      operator: 'and',
      filters: [
        {
          type: 'string',
          field: 'firstName',
          operator: 'startsWith',
          value: '',
        },
      ],
    });
  };

  useEffect(() => {
    if (!agencyProfileFilter) return;

    try {
      const parsedFilter = parse(agencyProfileFilter.filter as string);
      setFilter(parsedFilter);
    } catch (error) {
      toast.error('Error parsing filter');
      initFilter();
    }
  }, [agencyProfileFilter]);

  const handleCreate = () => {
    setIsDirty(true);
    initFilter();
  };

  const create = () => {
    if (!filter) return;

    setIsLoading(true);

    createAgencyProfileFilter(Number(id), JSON.stringify(filter))
      .then((response) => {
        setAgencyProfileFilter(response);
        setIsDirty(false);
        toast.success('Profile filter saved');
      })
      .catch(() => {
        toast.error('Error creating profile filter');
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const update = () => {
    if (!agencyProfileFilter) return;

    setIsLoading(true);

    updateAgencyProfileFilter(agencyProfileFilter.id, {
      filter: JSON.stringify(filter),
      disabled: agencyProfileFilter.disabled,
    })
      .then((response) => {
        setAgencyProfileFilter(response);
        setIsDirty(false);
        toast.success('Profile filter updated');
      })
      .catch(() => {
        toast.error('Error updating profile filter');
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleSave = () => {
    if (!agencyProfileFilter) create();
    else update();
  };

  const handleFilterChange = (changedFilter: Filter<Profile>) => {
    setFilter(changedFilter);
    setIsDirty(true);
  };

  const handleChangeEnabled: ChangeEventHandler<HTMLInputElement> = (event) => {
    setIsDirty(true);
    setAgencyProfileFilter((prev) => {
      if (!prev) return prev;

      return {
        ...prev,
        disabled: !event.target.checked,
      };
    });
  };

  return (
    <div className="p-6 flex flex-col gap-4">
      <div className="flex justify-between items-center">
        <PageTitle>Profile Filter for {agency?.name}</PageTitle>
        <div className="flex items-center gap-4">
          <Checkbox
            color="secondary"
            isDisabled={!agencyProfileFilter || isLoading}
            onChange={handleChangeEnabled}
            isSelected={!agencyProfileFilter?.disabled}
          >
            Enabled
          </Checkbox>
          <Button color="secondary" isDisabled={!isDirty || isLoading} onClick={handleSave}>
            Save changes
          </Button>
        </div>
      </div>
      {isLoading && !agencyProfileFilter ? (
        <div className="flex flex-col gap-2">
          <div className="flex items-center justify-between gap-4">
            <Skeleton className="rounded-xl">
              <div className="h-10 w-[160px] bg-default-300" />
            </Skeleton>
            <div className="flex items-center gap-2">
              <Skeleton className="rounded-xl">
                <div className="h-10 w-[94px] bg-default-300" />
              </Skeleton>
              <Skeleton className="rounded-xl">
                <div className="h-10 w-[103px] bg-default-300" />
              </Skeleton>
              <Skeleton className="rounded-xl">
                <div className="h-10 w-10 bg-default-300" />
              </Skeleton>
            </div>
          </div>
          <Card className="flex flex-col">
            {Array.from({ length: 3 }).map((_, index) => (
              <Fragment key={index}>
                <div className="p-2 flex items-center justify-between gap-2" key={index}>
                  <div className="flex items-center gap-2">
                    <Skeleton className="rounded-xl">
                      <div className="h-10 w-[200px] bg-default-300" />
                    </Skeleton>
                    <Skeleton className="rounded-xl">
                      <div className="h-10 w-[200px] bg-default-300" />
                    </Skeleton>
                    <Skeleton className="rounded-xl">
                      <div className="h-10 w-[200px] bg-default-300" />
                    </Skeleton>
                  </div>
                  <Skeleton className="rounded-xl">
                    <div className="h-10 w-10 bg-default-300" />
                  </Skeleton>
                </div>
                {index < 2 && <Divider />}
              </Fragment>
            ))}
          </Card>
        </div>
      ) : (
        <>
          {filter ? (
            <>
              <QueryBuilder
                fields={profileFilterFields}
                value={filter}
                onChange={handleFilterChange}
                disabled={isLoading || agencyProfileFilter?.disabled}
              />
              <Card className="p-4">
                <ReactJson src={filter} />
              </Card>
            </>
          ) : (
            <Card className="p-10 flex flex-col justify-center items-center gap-8">
              <p className="text-base font-medium text-neutral-500">No profile filter found.</p>
              <Button color="secondary" onClick={handleCreate}>
                Create profile filter
              </Button>
            </Card>
          )}
        </>
      )}
    </div>
  );
};
