import { AddOutlined, DeleteOutline, Edit } from '@mui/icons-material';
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  Select,
  SelectItem,
} from '@nextui-org/react';

import { IconButton } from '@mui/material';
import type { BaseRewardConfigData } from '@perry/shared-types';
import React from 'react';
import { useGetRewardConditionText } from '../hooks';
import { ProfileConditionForm } from './ProfileConditionForm';
import { ShiftConditionForm } from './ShiftConditionForm';
import { isValidConfigData } from './utils';

type RewardConfigDataCardRowProps = {
  translation: string;
  onOpen: () => void;
  onDelete: () => void;
};

const RewardConfigDataRow = ({ translation, onOpen, onDelete }: RewardConfigDataCardRowProps) => (
  <div className="flex flex-row items-center justify-between">
    <span className="text-md">{translation}</span>
    <div className="flex flex-row items-center">
      <IconButton onClick={onOpen}>
        <Edit />
      </IconButton>
      <IconButton onClick={onDelete}>
        <DeleteOutline />
      </IconButton>
    </div>
  </div>
);

type RewardConfigDataCardProps = {
  configData: BaseRewardConfigData[];
  setConfigData: (dataRows: BaseRewardConfigData[]) => void;
};

type RewardConfigEditModalProps = {
  configData: BaseRewardConfigData | 'create' | null;
  onSave: (val: BaseRewardConfigData) => void;
  onClose: () => void;
};

export const RewardConfigEditModal = ({
  onClose,
  onSave,
  configData,
}: RewardConfigEditModalProps) => {
  const [configPartial, setConfigPartial] = React.useState<Partial<BaseRewardConfigData>>(
    configData === 'create' || configData === null ? { type: 'shift' } : configData,
  );

  const isValidConfigPartial = React.useMemo(
    () => isValidConfigData(configPartial),
    [configPartial],
  );

  const handleSave = () => {
    if (isValidConfigData(configPartial)) onSave(configPartial);
    onClose();
  };

  React.useEffect(() => {
    if (configData === 'create' || configData === null) {
      setConfigPartial({ type: 'shift' });
    } else {
      setConfigPartial(configData);
    }
  }, [configData]);

  return (
    <Modal
      isOpen={configData !== null}
      onClose={onClose}
      size="5xl"
      className="w-[60%] min-h-[400px]"
    >
      <ModalContent>
        <ModalHeader>
          <span className="text-lg font-bold">
            {configData === 'create' ? 'Create' : 'Edit'} Reward Condition
          </span>
        </ModalHeader>
        <ModalBody>
          <div className="flex flex-col gap-3">
            <Select
              label="Condition Type"
              value={configPartial.type}
              size="sm"
              defaultSelectedKeys={['shift']}
              className="w-[50%]"
              onChange={(e) =>
                setConfigPartial({ type: e.target.value as BaseRewardConfigData['type'] })
              }
            >
              <SelectItem key="shift" value="shift">
                Shift
              </SelectItem>
              <SelectItem key="user" value="user">
                User
              </SelectItem>
            </Select>
            {configPartial.type === 'shift' && (
              <ShiftConditionForm
                configData={configPartial}
                setConfigData={(data) => setConfigPartial(data)}
              />
            )}
            {configPartial.type === 'user' && (
              <ProfileConditionForm
                configData={configPartial}
                setConfigData={(data) => setConfigPartial(data)}
              />
            )}
          </div>
        </ModalBody>
        <ModalFooter>
          <Button variant="light" onClick={onClose}>
            Cancel
          </Button>
          <Button color="primary" onClick={handleSave} disabled={!isValidConfigPartial}>
            Save
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export const RewardConfigDataCard = ({ configData, setConfigData }: RewardConfigDataCardProps) => {
  const [configModalOpen, setConfigModalOpen] = React.useState<number | 'create' | null>(null);
  const configDataWithTranslation = useGetRewardConditionText(configData);

  const onSave = React.useCallback(
    (val: BaseRewardConfigData) => {
      if (configModalOpen === null) return;

      if (configModalOpen === 'create') {
        setConfigData([...configData, val]);
      } else if (typeof configModalOpen === 'number') {
        setConfigData(configData.map((data, idx) => (idx === configModalOpen ? val : data)));
      }
    },
    [configModalOpen, configData, setConfigData],
  );

  const editingConfigData = React.useMemo(() => {
    if (configModalOpen === null) return null;
    if (configModalOpen === 'create') return 'create';
    return configData[configModalOpen];
  }, [configData, configModalOpen]);

  return (
    <Card className="p-2 pt-0">
      <CardHeader>
        <div className="flex flex-row justify-between w-full items-center">
          <span className="text-lg font-bold">Reward Conditions</span>
          <IconButton onClick={() => setConfigModalOpen('create')}>
            <AddOutlined />
          </IconButton>
        </div>
      </CardHeader>
      <CardBody>
        {configDataWithTranslation.length === 0
          ? 'No conditions set'
          : configDataWithTranslation.map((d, idx) => (
              <div className="flex flex-col gap-2">
                <RewardConfigDataRow
                  key={idx}
                  translation={d.translation}
                  onDelete={() => setConfigData(configData.filter((_, i) => i !== idx))}
                  onOpen={() => setConfigModalOpen(idx)}
                />
              </div>
            ))}
      </CardBody>
      <RewardConfigEditModal
        onClose={() => {
          setConfigModalOpen(null);
        }}
        onSave={onSave}
        configData={editingConfigData}
      />
    </Card>
  );
};
