import { Card, CardBody, CardHeader, Select, SelectItem } from '@nextui-org/react';
import type { BaseRewardUniquenessConfig, BaseRewardUniquenessField } from '@perry/shared-types';

type UniquenessConfigDataCardProps = {
  uniquenessConfig: BaseRewardUniquenessConfig;
  setUniquenessConfig: (config: BaseRewardUniquenessConfig) => void;
};

export const UniquenessConfigDataCard = ({
  setUniquenessConfig,
  uniquenessConfig,
}: UniquenessConfigDataCardProps) => (
  <Card className="p-2 pt-0">
    <CardHeader>
      <div className="flex flex-row justify-between w-full items-center">
        <span className="text-lg font-bold">Reward Limits</span>
      </div>
    </CardHeader>
    <CardBody>
      <Select
        label="Field Type"
        selectedKeys={uniquenessConfig.fields}
        value={uniquenessConfig.fields}
        onChange={(e) =>
          setUniquenessConfig({ fields: [e.target.value as BaseRewardUniquenessField] })
        }
        className="w-full"
      >
        <SelectItem key="id" value="id">
          One per object (visit, user, etc)
        </SelectItem>
        <SelectItem key="rewarded_date" value="rewarded_date">
          One per day
        </SelectItem>
        <SelectItem key="rewarded_year" value="rewarded_year">
          One per year
        </SelectItem>
      </Select>
    </CardBody>
  </Card>
);
