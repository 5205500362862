"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DayOfTheWeek = exports.PopupBannerRecurrence = void 0;
var PopupBannerRecurrence;
(function (PopupBannerRecurrence) {
    PopupBannerRecurrence["ALWAYS"] = "ALWAYS";
    PopupBannerRecurrence["WEEKLY"] = "WEEKLY";
    PopupBannerRecurrence["MONTHLY"] = "MONTHLY";
    PopupBannerRecurrence["ONCE"] = "ONCE";
})(PopupBannerRecurrence || (exports.PopupBannerRecurrence = PopupBannerRecurrence = {}));
var DayOfTheWeek;
(function (DayOfTheWeek) {
    DayOfTheWeek["SUNDAY"] = "SUNDAY";
    DayOfTheWeek["MONDAY"] = "MONDAY";
    DayOfTheWeek["TUESDAY"] = "TUESDAY";
    DayOfTheWeek["WEDNESDAY"] = "WEDNESDAY";
    DayOfTheWeek["THURSDAY"] = "THURSDAY";
    DayOfTheWeek["FRIDAY"] = "FRIDAY";
    DayOfTheWeek["SATURDAY"] = "SATURDAY";
})(DayOfTheWeek || (exports.DayOfTheWeek = DayOfTheWeek = {}));
