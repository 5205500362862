import { NextUIProvider } from '@nextui-org/react';
import Agencies from '@pages/Agencies';
import AgencyDetais from '@pages/Agencies/Details';
import { AgencyMissionsList } from '@pages/Agencies/Missions';
import MissionConfigDetails from '@pages/Agencies/Missions/Details/mission';
import RewardConfigDetails from '@pages/Agencies/Rewards/Details/reward';
import AgencyEditSms from '@pages/Agencies/SmsManager/EditSms';
import AgencyNewSms from '@pages/Agencies/SmsManager/NewSms';
import AgencySmsList from '@pages/Agencies/SmsManager/SmsList';
import Campaigns from '@pages/Campaigns';
import CampaignDetails from '@pages/Campaigns/Details';
import Home from '@pages/Home';
import { IntegrationsPage } from '@pages/integrations';
import JobPost from '@pages/JobPost';
import Login from '@pages/Login';
import { ProcessingStatusPage } from '@pages/ProcessingStatus';
import { Proposal } from '@pages/Proposal';
import { ProposalDetails } from '@pages/Proposal/components/proposalDetails';
import { ProposalContactPage } from '@pages/ProposalContact';
import Templates from '@pages/Templates';
import MissionTemplateDetails from '@pages/Templates/Details/mission';
import RewardTemplateDetails from '@pages/Templates/Details/reward';
import Users from '@pages/Users';
import UserDetails from '@pages/Users/Details';
import { theme as styleThemes } from '@styles/theme';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import App from './App';
import { UserProvider } from './context/userContext';
import reportWebVitals from './reportWebVitals';
// eslint-disable-next-line import/no-unassigned-import
import 'react-toastify/dist/ReactToastify.css';
// eslint-disable-next-line import/no-unassigned-import
import { ProfileFilterPage } from '@pages/Agencies/ProfileFilter';
// eslint-disable-next-line import/no-unassigned-import
import './index.css';
// eslint-disable-next-line import/no-unassigned-import
import 'react-phone-number-input/style.css';
import { AgencyRewardsList } from './pages/Agencies/Rewards';
import Invoices from './pages/Invoices';
const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

const queryClient = new QueryClient();

root.render(
  <NextUIProvider>
    <ThemeProvider theme={styleThemes}>
      <UserProvider>
        <QueryClientProvider client={queryClient}>
          <main className="light text-foreground bg-background">
            <BrowserRouter>
              <Routes>
                <Route path="/login" element={<Login />} />
                <Route path="/" element={<App />}>
                  <Route index element={<Home />} />
                  <Route path="/users">
                    <Route index element={<Users />} />
                    <Route path=":id" element={<UserDetails />} />
                  </Route>
                  <Route path=":agencyId/job-post" element={<JobPost />} />
                  <Route path="/agencies">
                    <Route index element={<Agencies />} />
                    <Route path=":id" element={<AgencyDetais />} />
                    <Route path=":id/sms-manager" element={<AgencySmsList />} />
                    <Route path=":id/rewards" element={<AgencyRewardsList />} />
                    <Route path=":id/rewards/:rewardId" element={<RewardConfigDetails />} />
                    <Route path=":id/missions" element={<AgencyMissionsList />} />
                    <Route path=":id/missions/:missionId" element={<MissionConfigDetails />} />
                    <Route path=":id/new-sms" element={<AgencyNewSms />} />
                    <Route path=":id/edit-sms/:jobId" element={<AgencyEditSms />} />
                    <Route path=":id/profile-filter" element={<ProfileFilterPage />} />
                  </Route>
                  <Route path="/proposal">
                    <Route index element={<Proposal />} />
                    <Route path=":id" element={<ProposalDetails />} />
                  </Route>
                  <Route path="/invoices" element={<Invoices />} />
                  <Route path="/proposal-contact" element={<ProposalContactPage />} />
                  <Route path="/integrations" element={<IntegrationsPage />} />
                  <Route path="/campaigns">
                    <Route index element={<Campaigns />} />
                    <Route path="new" element={<CampaignDetails />} />
                    <Route path=":id" element={<CampaignDetails />} />
                  </Route>
                  <Route path="/templates">
                    <Route index element={<Templates />} />
                    <Route path="mission/new" element={<MissionTemplateDetails />} />
                    <Route path="reward/:id" element={<RewardTemplateDetails />} />
                    <Route path="mission/:id" element={<MissionTemplateDetails />} />
                    <Route path="reward/new" element={<RewardTemplateDetails />} />
                  </Route>
                  <Route path="/processing-status" element={<ProcessingStatusPage />} />
                </Route>
                <Route path="*" element={<>Not found</>} />
              </Routes>
            </BrowserRouter>
          </main>
        </QueryClientProvider>
      </UserProvider>
    </ThemeProvider>
  </NextUIProvider>,
);

reportWebVitals();
