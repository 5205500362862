import PageTitle from '@components/PageTitle';
import { Button, Input, Textarea } from '@nextui-org/react';
import type { BaseCreateMissionTemplateParams, BaseRewardConfigData } from '@perry/shared-types';
import {
  createMissionTemplate,
  getMissionTemplate,
  updateMissionTemplate,
} from '@services/templates';
import _ from 'lodash';
import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { MissionEvaluationConfigDataCard } from './components/MissionEvaluationConfigDataCard';
import { MissionRecurrenceConfigDataCard } from './components/MissionRecurrenceConfigDataCard';
import { RewardConfigDataCard } from './components/RewardConfigDataCard';
import { DefaultMissionTemplate } from './utils';

const MissionTemplateDetails = () => {
  const { id } = useParams<{ id: string }>();
  const isNewTemplate = !id;
  const [template, setTemplate] = React.useState<BaseCreateMissionTemplateParams | null>(null);

  const [formData, setFormData] =
    React.useState<BaseCreateMissionTemplateParams>(DefaultMissionTemplate);

  const navigate = useNavigate();

  const handleUpdate = (update: Partial<BaseCreateMissionTemplateParams>) => {
    setFormData((params) => ({ ...params, ...update }));
  };

  const handleUpdateRewardConfig = (update: BaseRewardConfigData[]) => {
    setFormData((params) => ({
      ...params,
      rewardTemplates: params.rewardTemplates.map((t) => ({ ...t, configData: update })),
    }));
  };

  const handleSubmit = async () => {
    try {
      if (isNewTemplate) {
        const response = await createMissionTemplate(formData);
        if (!response.template) {
          toast.error('Failed to create reward template.');
          return;
        }
        toast.success('Reward Template created successfully');
      } else {
        const response = await updateMissionTemplate(parseInt(id), formData);
        if (!response.template) {
          toast.error('Failed to update reward template.');
          return;
        }
        toast.success('Reward template updated successfully');
      }
      navigate('/templates');
    } catch (error) {
      console.error(error);
      toast.error(isNewTemplate ? 'Error creating mission' : 'Error updating mission');
    }
  };

  const handleCancel = () => {
    navigate('/templates');
  };

  const getSelectedTemplate = React.useCallback(async () => {
    if (!id) return;

    const response = await getMissionTemplate(id);
    if (response) setTemplate(response.template);
  }, [id]);

  React.useEffect(() => {
    if (!isNewTemplate) {
      getSelectedTemplate();
    }
  }, [getSelectedTemplate, isNewTemplate]);

  React.useEffect(() => {
    if (template) {
      setFormData({
        title: template.title,
        description: template.description,
        pointAmount: template.pointAmount,
        missionEvaluationConfig: template.missionEvaluationConfig,
        recurrenceConfig: template.recurrenceConfig,
        missionTranslations: template.missionTranslations,
        rewardTemplates: template.rewardTemplates,
      });
    }
  }, [template]);

  const saveIsDisabled = React.useMemo(() => _.isEqual(formData, template), [formData, template]);

  return (
    <>
      <div className="flex flex-col h-full w-full">
        <PageTitle>
          {isNewTemplate ? 'Create Mission Template' : 'Mission Template Edit Details'}
        </PageTitle>
        <div className="grid grid-cols-12 gap-4 mt-6 grow">
          <div className="col-span-6 flex flex-col gap-4">
            <Input
              label="Title"
              type="text"
              labelPlacement="outside"
              name="title"
              value={formData.title}
              onChange={(e) => handleUpdate({ title: e.target.value })}
            />
            <Textarea
              label="Description"
              type="text"
              labelPlacement="outside"
              name="description"
              value={formData.description}
              minRows={2}
              onChange={(e) => handleUpdate({ description: e.target.value })}
            />
            <Input
              label="Point Value"
              type="number"
              labelPlacement="outside"
              name="pointAmount"
              value={formData.pointAmount?.toString()}
              onChange={(e) => handleUpdate({ pointAmount: parseInt(e.target.value) })}
            />
            <MissionEvaluationConfigDataCard
              missionEvaluationConfig={formData.missionEvaluationConfig}
              setMissionEvaluationConfig={(data) => handleUpdate({ missionEvaluationConfig: data })}
            />
          </div>
          <div className="col-span-6 flex flex-col gap-4">
            <MissionRecurrenceConfigDataCard
              missionRecurrenceConfig={formData.recurrenceConfig}
              setMissionRecurrenceConfig={(data) => handleUpdate({ recurrenceConfig: data })}
            />
            {/* TODO: Add config for the reward config trigger type when supported*/}
            <RewardConfigDataCard
              configData={formData.rewardTemplates[0].configData}
              setConfigData={(data) => handleUpdateRewardConfig(data)}
            />
          </div>
        </div>
        <div className="flex justify-end gap-6 mt-6">
          <Button type="button" variant="bordered" className="w-56" onClick={handleCancel}>
            Cancel
          </Button>
          <Button
            onClick={handleSubmit}
            disabled={saveIsDisabled}
            variant="solid"
            className="w-56 bg-theme-primary text-white"
          >
            {isNewTemplate ? 'Create' : 'Save'}
          </Button>
        </div>
      </div>
    </>
  );
};

export default MissionTemplateDetails;
