import type { BaseRewardConfigData } from '@perry/shared-types';
import React from 'react';

export const getEnglishVersionOfConfig = (config: BaseRewardConfigData): string => {
  if (config.type === 'shift') {
    const condition = config.condition;
    if (condition.type === 'date') {
      if (condition.comparisonType === 'in_threshold') {
        return `${condition.field} is within ${condition.thresholdMinutes} minutes of ${condition.comparedToDate}`;
      } else if (condition.comparisonType === 'lte') {
        return `${condition.field} is less than or equal to ${condition.comparedToDate}${condition.minutesDelay ? ` plus ${condition.minutesDelay} minutes` : ''}`;
      } else if (condition.comparisonType === 'gte') {
        return `${condition.field} is greater than or equal to ${condition.comparedToDate}${condition.minutesDelay ? ` plus ${condition.minutesDelay} minutes` : ''}`;
      } else if (condition.comparisonType === 'null') {
        return `${condition.field} is null`;
      } else if (condition.comparisonType === 'not_null') {
        return `${condition.field} is not null`;
      } else if (condition.comparisonType === 'is_holiday') {
        return `${condition.field} is on the holidays (${condition.holidays.join(', ')})`;
      }
    } else if (condition.type === 'number') {
      if (condition.comparisonType === 'eq') {
        return `${condition.field} equals ${condition.comparedValue}`;
      } else if (condition.comparisonType === 'lte') {
        return `${condition.field} is less than or equal to ${condition.comparedValue}`;
      } else if (condition.comparisonType === 'gte') {
        return `${condition.field} is greater than or equal to ${condition.comparedValue}`;
      } else if (condition.comparisonType === 'null') {
        return `${condition.field} is null`;
      } else if (condition.comparisonType === 'not_null') {
        return `${condition.field} is not null`;
      }
    } else if (condition.type === 'string') {
      if (condition.comparisonType === 'eq') {
        return `${condition.field} equals "${condition.comparedToValue}"`;
      } else if (condition.comparisonType === 'neq') {
        return `${condition.field} does not equal "${condition.comparedToValue}"`;
      } else if (condition.comparisonType === 'null') {
        return `${condition.field} is null`;
      } else if (condition.comparisonType === 'not_null') {
        return `${condition.field} is not null`;
      } else if (condition.comparisonType === 'contains') {
        return `${condition.field} contains "${condition.comparedToValue}"`;
      } else if (condition.comparisonType === 'not_contains') {
        return `${condition.field} does not contain "${condition.comparedToValue}"`;
      } else if (condition.comparisonType === 'contains_one_of') {
        return `${condition.field} contains one of [${condition.comparedToValue.join(', ')}]`;
      } else if (condition.comparisonType === 'not_contains_one_of') {
        return `${condition.field} does not contain one of [${condition.comparedToValue.join(', ')}]`;
      } else if (condition.comparisonType === 'is_one_of') {
        return `${condition.field} is one of [${condition.comparedToValue.join(', ')}]`;
      } else if (condition.comparisonType === 'is_not_one_of') {
        return `${condition.field} is not one of [${condition.comparedToValue.join(', ')}]`;
      }
    } else if (condition.type === 'boolean') {
      return `${condition.field} is ${condition.comparisonType === 'is_true' || condition.comparisonType === 'truthy' ? 'true' : 'false'}`;
    }
  } else if (config.type === 'user') {
    const condition = config.condition;
    if (condition.type === 'date') {
      if (condition.comparisonType === 'same_date') {
        return `${condition.field} is today`;
      }
    } else if (condition.type === 'string') {
      if (condition.comparisonType === 'contains') {
        return `${condition.field} contains "${condition.comparedToValue}"`;
      } else if (condition.comparisonType === 'eq') {
        return `${condition.field} equals "${condition.comparedToValue}"`;
      } else if (condition.comparisonType === 'neq') {
        return `${condition.field} does not equal "${condition.comparedToValue}"`;
      } else if (condition.comparisonType === 'not_contains') {
        return `${condition.field} does not contain "${condition.comparedToValue}"`;
      } else if (condition.comparisonType === 'contains_one_of') {
        return `${condition.field} contains one of [${condition.comparedToValue.join(', ')}]`;
      } else if (condition.comparisonType === 'not_contains_one_of') {
        return `${condition.field} does not contain one of [${condition.comparedToValue.join(', ')}]`;
      } else if (condition.comparisonType === 'is_not_one_of') {
        return `${condition.field} is not one of [${condition.comparedToValue.join(', ')}]`;
      } else if (condition.comparisonType === 'is_one_of') {
        return `${condition.field} is one of [${condition.comparedToValue.join(', ')}]`;
      }
    }
  }
  throw new Error(`Unhandled config type: ${config.type}`);
};

export const useGetRewardConditionText = (
  configData: BaseRewardConfigData[],
): { translation: string; config: BaseRewardConfigData }[] =>
  React.useMemo(() => {
    if (configData.length === 0) return [];
    return configData.map((config) => ({ translation: getEnglishVersionOfConfig(config), config }));
  }, [configData]);
