import type { BaseCreateMissionTemplateParams } from '@perry/shared-types';

export const DefaultMissionTemplate: BaseCreateMissionTemplateParams = {
  title: ' ',
  description: '',
  missionEvaluationConfig: [],
  pointAmount: 0,
  missionTranslations: {
    spanish: {
      startedPostText: '',
      completedPostText: '',
      startedDescription: '',
      completedDescription: '',
      failedDescription: '',
    },
    english: {
      startedPostText: '',
      completedPostText: '',
      startedDescription: '',
      completedDescription: '',
      failedDescription: '',
    },
  },
  recurrenceConfig: {
    type: 'one_time',
    startDate: new Date(),
    endDate: new Date(),
  },
  rewardTemplates: [
    {
      title: '',
      description: '',
      configData: [],
      uniquenessConfig: { fields: ['id'] },
      multiplierConfig: null,
      pointAmount: 0,
      visibility: 'PRIVATE',
      name: '',
      triggerType: 'VISIT',
    },
  ],
};
