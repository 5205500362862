import PageTitle from '@components/PageTitle';
import { Search } from '@mui/icons-material';
import {
  Button,
  Input,
  Spinner,
  Table,
  TableBody,
  TableCell,
  TableColumn,
  TableHeader,
  TableRow,
  getKeyValue,
} from '@nextui-org/react';
import { useInfiniteScroll } from '@nextui-org/use-infinite-scroll';
import { useAsyncList } from '@react-stately/data';
import { getAgencies } from '@services/agencies';
import type { AgencyObject } from '@services/agencies/types';
import { getInvoiceArrAging } from '@services/invoices';
import { formatDate } from '@utils/date';
import debounce from 'lodash.debounce';
import type { ChangeEvent, Key } from 'react';
import React, { useCallback, useEffect, useState } from 'react';

const getAgencyColumns = () => [
  {
    key: 'name',
    label: 'AGENCY',
  },
  {
    key: 'launchDate',
    label: 'LAUNCH DATE',
  },
  {
    key: 'activeCaregivers',
    label: 'ACTIVE CARE TEAM',
  },
];

const Invoices = () => {
  const [filter, setFilter] = useState<string>('');
  const [isLoading, setIsLoading] = useState(true);
  const [hasMore, setHasMore] = useState(false);
  const [firstLoad, setFirstLoad] = useState(true);
  const [isDownloading, setIsDownloading] = useState(false);

  const list = useAsyncList({
    async load({ cursor }) {
      if (cursor) {
        setIsLoading(false);
      }

      setIsLoading(true);
      setFirstLoad(true);

      const res = await getAgencies(cursor, '25', 'active', filter);

      setIsLoading(false);
      setFirstLoad(false);

      setHasMore(res.length === 25);

      return {
        items: res,
        cursor: cursor ? (parseInt(cursor, 10) + 1).toString() : '2',
      };
    },
  });

  const handleOnChange = (e: ChangeEvent<HTMLInputElement>) => {
    list.items = [];
    setIsLoading(true);
    setFilter(e.target.value);
  };

  const handleDownload = React.useCallback(async () => {
    setIsDownloading(true);
    const blob = await getInvoiceArrAging(filter);
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = 'invoice_aging_report.csv';
    a.click();
    setIsDownloading(false);
  }, [filter]);

  useEffect(() => {
    if (!firstLoad) {
      list.reload();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter]);

  const debounceOnChange = debounce(handleOnChange, 500);

  const [loaderRef, scrollerRef] = useInfiniteScroll({
    hasMore,
    onLoadMore: list.loadMore,
  });

  const renderCell = useCallback((agency: AgencyObject, columnKey: Key) => {
    switch (columnKey) {
      case 'launchDate':
        return formatDate(agency.launchDate);

      case 'created':
        return formatDate(agency.created);

      case 'deactivatedDate':
        return formatDate(agency.deactivatedDate);

      default:
        return getKeyValue(agency, columnKey.toString());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <div className="flex flex-row justify-between">
        <PageTitle>Invoices</PageTitle>
        <div className="flex flex-row gap-2">
          <Button
            onClick={handleDownload}
            isLoading={isDownloading}
            isDisabled={isDownloading}
            style={{
              background: '#6558F5',
              color: 'white',
            }}
          >
            Download Invoice Aging Report
          </Button>
        </div>
      </div>
      <Input
        aria-label="Search"
        size="sm"
        startContent={<Search />}
        onChange={debounceOnChange}
        placeholder="Search..."
        className="w-72 mt-8 mb-4"
      />
      <Table
        baseRef={scrollerRef}
        aria-label="Agencies table"
        isStriped
        shadow="md"
        bottomContent={
          hasMore ? (
            <div className="flex justify-center w-full">
              <Spinner ref={loaderRef} color="primary" />
            </div>
          ) : null
        }
        classNames={{
          base: 'max-h-[70vh] overflow-auto p-3',
          table: 'max-h-[50vh]',
        }}
      >
        <TableHeader columns={getAgencyColumns()}>
          {(column) => (
            <TableColumn className="text-white bg-purple-500" key={column.key}>
              {column.label}
            </TableColumn>
          )}
        </TableHeader>
        <TableBody
          isLoading={isLoading}
          items={list.items as AgencyObject[]}
          loadingContent={<Spinner color="secondary" />}
          emptyContent={'No rows to display.'}
        >
          {(item: AgencyObject) => (
            <TableRow key={item.id}>
              {(columnKey) => <TableCell>{renderCell(item, columnKey)}</TableCell>}
            </TableRow>
          )}
        </TableBody>
      </Table>
    </div>
  );
};

export default Invoices;
